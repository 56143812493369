<template>
    <div class="shield">
        <div class="diamonds">

            <img :src="getDiamond(1)" :class="{ animated: currentLocation === '1' }" style="position:absolute; width:85px;left:19px; top:33px;" />
            <img :src="getDiamond(2)" :class="{ animated: currentLocation === '2' }" style="position:absolute; width:85px;left:70px; top:78px; " />
            <img :src="getDiamond(3)" :class="{ animated: currentLocation === '3' }" style="position:absolute; width:85px;left:41px; top:220px;" />
            
            <img :src="getDiamond(4)" :class="{ animated: currentLocation === '4' }" style="position:absolute; width:75px;left:100px; top:260px; " />
            <img :src="getDiamond(5)" :class="{ animated: currentLocation === '5' }" style="position:absolute; width:75px;left:12px; top:153px;" />
            <img :src="getDiamond(6)" :class="{ animated: currentLocation === '6' }" style="position:absolute; width:75px;left:150px; top:50px;" />

            <img :src="getDiamond(7)" :class="{ animated: currentLocation === '7' }" style="position:absolute; width:73px;left:187px; top:178px;" />
            <img :src="getDiamond(8)" :class="{ animated: currentLocation === '8' }" style="position:absolute; width:73px;left:210px; top:114px;" />
            <img :src="getDiamond(9)" :class="{ animated: currentLocation === '9' }" style="position:absolute; width:73px;left:0px; top:88px;" />

            <img :src="getDiamond(10)" :class="{ animated: currentLocation === '10' }" style="position:absolute; width:68px;left:219px; top:33px;" />
            <img :src="getDiamond(11)" :class="{ animated: currentLocation === '11' }" style="position:absolute; width:68px;left:113px; top:189px;"  />
            <img :src="getDiamond(12)" :class="{ animated: currentLocation === '12' }" style="position:absolute; width:68px;left:149px; top:118px;" />
            
            <img :src="getDiamond(13)" :class="{ animated: currentLocation === '13' }" style="position:absolute; width:75px;left:77px; top:142px;"/>
            <img :src="getDiamond(14)" :class="{ animated: currentLocation === '14' }" style="position:absolute; width:75px;left:107px; " />            

            <img :src="getDiamond(15)" :class="{ animated: currentLocation === '15' }" style="position:absolute; width:75px;left:107px; left:160px; top:230px; " />            

        </div>
        <img class="shieldimg" :src="`./static/images/shield.png`" />      
    </div>
</template>

<script>

export default {
    name: "Shield",
    components: {
    },    
    data() {
        return {

        };
    },
    methods: {
        getDiamond(id) {
            id = id.toString();
            let result = this.$store.getters['game/answers'][id];

            if (result != '') {
                // there is a previous result, find out if correct or wrong
                let location = this.$store.getters['gamesettings/locations'].find(location => location.id === id);

                if (result === "correct"){
                    // correct answer: show diamond
                    return "./static/images/diamonds/" + location.prize;
                }
                else if (result === "incorrect"){
                    // incorrect answer
                    return "./static/images/diamonds/" + location.error;
                }
            }
        }
    },
    mounted() {

    },
    computed: {
       currentLocation() {
           if (this.$store.getters['game/currentLocation']) {
                return this.$store.getters['game/currentLocation'].id;
           }
           else {
               return '';
           }
       }
    },
};
</script>


<style scoped>



.shield {
    position: absolute;
    width: calc(950px / var(--scale) );
    height: calc(710px / var(--scale) );
    display: flex;
    justify-content: center; 
    z-index:2;
}

.diamonds {
    position: absolute;
    width: calc(380px / var(--scale) );
    height: calc(450px / var(--scale) );
    top: calc(160px / var(--scale) );
    margin-left: calc(5px / var(--scale) );


    /* background-image : url('~@/assets/diamond-example.png');
    background-size: contain;
    background-repeat: no-repeat; */
    /* opacity: .4; */
    z-index:3;
}
.shieldimg {
    position: absolute;
    width: calc(660px / var(--scale) );
    height: calc(718px / var(--scale) );
}

.animated {
    animation: blink .5s linear 5;
}

@keyframes blink{
    0%{opacity: 0;}
    100%{opacity: 1;}
}

</style>