<template>
    <ul class="answers">
        <draggable
            :list="this.mutable_answers"
            ghost-class="ghost"
            :move="resetResult"
            @start="dragging = true"
            @end="dragging = false"
            >
                <drag-drop-answer 
                    v-for="(answer,index) in this.mutable_answers"  
                    :key="index" 
                    :text="answer.text"
                    :order="Number(answer.order)"
                    ref="answers"
                    />
            </draggable>                    
    </ul>
</template>

<script>

import DragDropAnswer from '@/components/student/QuestionPanel/DragDropAnswer.vue';
import draggable from 'vuedraggable'  
  
export default {
    name: "DragDropQuestion",
    components: {
      DragDropAnswer,
      draggable
    },    
    props: {
    },
    data() {
        return {
            mutable_answers:[],
            dragging: false
        };
    },
    methods: {
        resetResult() {
            this.$refs.answers.forEach( (answer) => {
                answer.reset();
                
            });
        },
        checkResult() {
            let index = 0;
            let correct_answers = 0;
            this.$refs.answers.forEach( (answer) => {
                let result = '';
                if (answer.order === index) {
                    result = "correct";
                    correct_answers++;
                }
                else {
                    result = "incorrect";
                }
                answer.setFeedback(result);
                index++;                
            });

            if (correct_answers === this.$refs.answers.length) {
                this.$emit("result","correct");
            }
            else {
                this.$emit("result","incorrect");
            }
        }   
    },
    mounted() {
        this.mutable_answers = JSON.parse(JSON.stringify( this.$store.getters['game/currentQuestion'].answers ));//  {...this.items,prop: newOne}; 
    },
    computed: {},
};
</script>


<style scoped>



.answers {
    list-style: none;
    margin:0;
    padding:0;
    /* margin-bottom: calc(20px / var(--scale) );   */
}

.ghost {
  opacity: 0;
  /* background: #ffffff; */
}

</style>