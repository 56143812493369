<template>
  <div class="panel">
        <question-panel-intro  v-if="state === 'intro'" @next="handleNext" @prev="handlePrev" />
        <question-panel-question  v-if="state === 'question'" @next="handleNext" @prev="handlePrev"  />
        <shield-panel  v-if="state === 'shield'"  @next="handleNext" />
  </div>
</template>

<script>

import QuestionPanelIntro from "@/components/student/QuestionPanel/QuestionPanelIntro.vue";
import QuestionPanelQuestion from "@/components/student/QuestionPanel/QuestionPanelQuestion.vue";
import ShieldPanel from "@/components/student/ShieldPanel/ShieldPanel.vue";


export default {
    name: "QuestionPanel",
    question : null,
    components: {
        QuestionPanelIntro,
        QuestionPanelQuestion,
        ShieldPanel,
    },
    props: {
        // question: {
        //     type: Object,
        // },
    },
    data() {
        return {
            state: 'intro'
        };
    },
    methods: {
        handleNext() {
            if (this.state === "intro"){
                this.state = "question";
            }
            else if (this.state === "question"){
                this.state = "shield";
            }
            else if (this.state === "shield"){
                this.$emit("close");
            }
        },
        handlePrev() {
            if (this.state === "intro"){
                this.$emit("close")
            }
            else if (this.state === "question"){
                this.state = "intro";
            }
        },
    },
    mounted() {
        let title = this.$store.getters['game/currentLocation'].title ;
        // get rid of breaks in the title, they are only used on the map
        //console.log(title);
        title = title.replace("<br/>", ' ');
        this.$store.dispatch("setHeaderContent", {caption: title } );
    },
    computed: {},
};
</script>


<style scoped>

.panel {
    position: absolute;
    background-color: var(--green-middle-color);
    width: 100%;
    height: 100%;
    z-index: 3;

}



</style>