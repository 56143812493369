<template>
    <div v-if="(message != '')"  class="panel">
        <div class="backdrop"></div>        
        <div class="popup" :class="id">
            <div class="panel-left">
                <div class="message" v-html="message">

                </div>
            </div>
            <div class="panel-right">
                <app-button color="purple" bevel="true" :caption="$t('button-next')"  @click="handleClose" />
                <div class="timerbar">
                    <div class="backgroundbar"></div>
                    <div :style="progress" class="progressbar"></div>
                </div>
            </div>
        </div>    
    </div>

</template>

<script>
    import AppButton from  "@/components/ui/AppButton.vue";
    
    export default {
        name: 'timed-popup-panel',
        components: {
            AppButton
        },
        data() {
            return {
                message : '',
                id: '',
                progresswidth: 0,
                time: 70
            }
        },
        created () {
            this.timer = null;
        },
        computed: {
            progress() {
                return {
                    width: this.progresswidth + '%'
                }
            }
        },
        methods: {   
            show(id){

                this.message = this.$i18n.t('popup-message-' + id);
                this.id = id;

                this.progresswidth = 0;
                clearInterval(this.timer);
                this.timer = setInterval(() => {
                    if (this.progresswidth >= 100) {
                        clearInterval(this.timer);
                        this.progresswidth = 0;
                        this.handleClose();
                    } 
                    else {
                        this.progresswidth ++;
                    }
                }
                , this.time);                
            },
            hide() {
                this.message = '';
            },
            handleClose() {
                clearInterval(this.timer);
                this.$emit("close");
            },
        }
    };
</script>


<style scoped>

.panel {
    width: 100%;
    height: 100%;   
}

.backdrop {
    position: absolute;
    background-color: black;
    opacity: 0.1;
    width: 100%;
    height: 100%;   
    z-index: 1;
}

.popup {
    position: absolute;
    background-color: var(--green-middle-color);
    width: 100%;
    top: calc(190px / var(--scale) ); 
    height: calc(380px / var(--scale) );    
    z-index: 3;
    display: flex;
    flex-direction: row;
}

.out-of-bounds {
    background-image: url("/static/images/background-out-of-bounds.png");
    background-size: cover;
}

.panel-left {
    width:75%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.message {
    /* margin-left: calc(-150px / var(--scale) );  */
    width: calc(700px / var(--scale) );
    color: var(--purple-dark-color); 
    font: calc(35px / var(--scale) )/calc(50px / var(--scale) ) Fira Sans Bold;   
}

.panel-right {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items:flex-end;
    height: 100%;
}

.timerbar {
    position: relative;
    margin-top: 20px;
    width: calc(155px / var(--scale) );
    height: 12px;

/* background-color: white; */
}

.progressbar {
    position: absolute;
    width: 12%;
    height: 100%;
    background-color: white;
}

.backgroundbar {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.5;
}


</style>
