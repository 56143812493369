<template>
  <div class="panel" :class="endgamecomplete ? 'endgame-bg' : ''">
    <div class="left">
        
        <shield class="shield" />
        
        <div v-if="endgamecomplete">
            <img class="credo-front" :src="`./static/images/shield_credo_front.png`" />  
            <img class="credo-back" :src="`./static/images/shield_credo_back.png`" />  
        </div>
        <div v-else class="shield-background">
            <div :class="['backdrop-' + this.result ]"></div>     
            <div class="stars-left" v-if="this.result === 'correct'">
                <animated-star 
                        v-for="index in 4"  
                        :key="index" 
                        :index="index"
                />
            </div> 
            <div class="stars-right" v-if="this.result === 'correct'">
                <animated-star 
                        v-for="index in 4"  
                        :key="index" 
                        :index="index"
                />
            </div> 
        </div>
    </div>
    <div class="right">
        <div class="score">
            <span>{{ score }} punten</span>
        </div>
        <div v-if="endgamecomplete" class="rank">
            {{ ranking }}
        </div>
        <div v-else>
            <div v-if="this.result === 'correct'" class="done">
                GOED GEDAAN!
            </div>
            <app-button color="purple" bevel="true" :caption="(complete)? $t('button-done'): $t('button-next')"  @click="handleNext" />
        </div>
    </div>
  </div>
</template>

<script>

import AppButton from  "@/components/ui/AppButton.vue";
import AnimatedStar from  "@/components/student/ShieldPanel/AnimatedStar.vue";
import Shield from  "@/components/student/ShieldPanel/Shield.vue";

export default {
    name: "ShieldPanel",
    components: {
      AppButton,
      AnimatedStar,
      Shield
    },    
    data() {
        return {

        };
    },
    methods: {
        handleNext() {
            this.$emit("next");
        },
    },
    mounted() {

    },
    computed: {
        endgamecomplete() {
            return this.$store.getters["game/endgamecomplete"];
        },
        result() {
            return this.$store.getters["game/currentAnswer"];
        },
        complete() {
            return this.$store.getters["game/gamecomplete"]   
        },
        score () {
            return this.$store.getters["game/score"]   
        },
        ranking () {
            //return this.$store.getters["game/ranking"]   
            let lb = this.$store.getters["groups/leaderboard"];
            let ranking = lb.findIndex( group => group.groupname === this.$store.getters['user/groupname'] ) + 1;
            return ranking;
        }
    },
};
</script>


<style scoped>

.panel {
    position: absolute;
    background-color: var(--green-middle-color);
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
}

.endgame-bg {
    background-image: url("/static/endgame/images/bg-game-with-clouds.png");
    background-color: #7FD9F2;
 
}

.credo-back {
    position: absolute;
    bottom: calc(30px / var(--scale) );
    left: calc(130px / var(--scale) );
    width: calc(660px / var(--scale) );
}

.credo-front {
    position: absolute;
    z-index:6;
    bottom: calc(30px / var(--scale) );
    left: calc(130px / var(--scale) );
    width: calc(660px / var(--scale) );
}

.left {
    width:70%;
    padding-top: calc(40px / var(--scale) );
    display: flex;
    align-items: center;
}

.stars-left {
    position: absolute;
    left:0;
    width:23%;
    height:100%;
}

.stars-right {
    position: absolute;
    right:0;
    width:23%;
    height:100%;
}

.shield-background {
    position: absolute;
    width: calc(950px / var(--scale) );
    height: calc(710px / var(--scale) );

}

.backdrop-correct {
    position: absolute;
    top: calc(-206px / var(--scale) );
    left: calc(-150px / var(--scale) );
    width: calc(1242px / var(--scale) );
    height: calc(1242px / var(--scale) );
    background: transparent radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
}

.backdrop-incorrect {
    position: absolute;
    top: calc(-206px / var(--scale) );
    left: calc(-120px / var(--scale) );
    width: calc(1120px / var(--scale) );
    height: calc(1120px / var(--scale) );
    background: transparent radial-gradient(closest-side at 50% 50%, #000000 0%, #00000000 100%) 0% 0% no-repeat padding-box;
    opacity: 0.5;
}

.right {
    width:23%;
    display: flex;
    flex-direction: column;
    align-items:center;

    /* background-color: green; */
    /* justify-content:space-evenly; */
    

    padding-top: calc(240px / var(--scale) ); 

    z-index:10;
    /* border:1px solid red; */
    /* justify-content: center; */
}


.score {
    color: var(--purple-dark-color);
    font: calc(34px / var(--scale) )/calc(42px / var(--scale) ) Fira Sans Regular;
    margin-bottom:60px;
}

.score span {
    font: calc(34px / var(--scale) )/calc(42px / var(--scale) ) Fira Sans bold;
}

.rank {
    color: var(--purple-dark-color);
    font: calc(400px / var(--scale) )/calc(280px / var(--scale) ) Fira Sans Bold;
}

.done {
    font: calc(40px / var(--scale) )/calc(35px / var(--scale) ) Fira Sans Bold;
    color: white;
    margin-bottom:60px;
}
</style>