<template>
  <div class="page">
    <div class="map-panel">
        <div class="map" id="map"></div>        
    </div>
    <!-- <app-button v-if="showQuitButton" class="roundquitbutton" color="purple" bevel="false" :caption="$t('button-quit-round')"  @click="handleLevelQuit" /> -->
    <ranking-panel class="ranking-panel" />
    <question-panel class="question-panel" v-if="this.$store.getters['game/currentLocation']" @close="handleQuestionClose" />
    <popup-panel ref="popup" class="popup-panel" @close="handlePopupClose" />
    <game-wait v-if="(this.$route.params.option === 'wait')" @click="(showSelfStart = true)" />
    <self-start-panel v-if="(this.showSelfStart)" @hide="showSelfStart=false" />
    <div v-if="!hasLocation" class="location-waitscreen">
        <div class="backdrop"></div>
        <div class="message"  v-html="$t('caption-wait-for-location')"></div>
    </div>
</div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import "@/assets/css/QuestionMarker.css";
import {QuestionMarker} from "@/classes/QuestionMarker"

import "@/assets/css/UserMarker.css";
import {UserMarker} from "@/classes/UserMarker"

import QuestionPanel from "@/components/student/QuestionPanel/QuestionPanel.vue";
import RankingPanel from "@/components/student/RankingPanel.vue";
import PopupPanel from "@/components/ui/PopupPanel.vue";
import GameWait from  "@/components/student/GameWait.vue";
import SelfStartPanel from "@/components/student/SelfStartPanel.vue";

import SocketClientHelper from "@/helpers/SocketClientHelper";
//import createLog from 'localstorage-logger';

import {Loggerage} from 'loggerage';

//const { Loggerage, LoggerageLevel } = require("loggerage");
// import AppButton from  "@/components/ui/AppButton.vue";


export default {
    name: "MapScreen",
    map: null,
    bounds: null,
    user: null,
    debuglabel: null,
    debugid: '',
    debuglatlng: null,
    markerGroup: null,
    logger: null,
    components: {
        QuestionPanel,
        RankingPanel,
        PopupPanel,
        GameWait,
        SelfStartPanel
        // AppButton,
    },
    data() {
 
        return {
            // questions: [],
            MODE: 'debug',
            questionmarkers: [],
            endgamemarkers: [],
            zoom: 17,
            // minZoom: 16,
            // maxZoom: 18,
            center: L.latLng(52.08275898178785, 4.312721966266951),
            // cornerSW: L.latLng(52.0813942284161, 4.3094755),
            // cornerNE: L.latLng(52.08365265578971, 4.3146345),
            offsetlat:0,
            offsetlng:0,
            gamebounds: null,
            leafletOptions : {
                zoomSnap: 0.1,
                zoomControl: false,
                maxBoundsViscosity: 1.0,
                gestureHandling:false,
                attributionControl:false 
            },
            gpsOptions: {
                enableHighAccuracy: true,
                timeout: 2999,
                maximumAge: 0,
            },
            watchTracker: null,
            showSelfStart: false,
            isStarted: false,
            hasLocation: false
            // showQuitButton:false,
            //timeOut: false
        };
    },
    methods: {
        handlePopupClose() {
            this.$refs.popup.hide();
        },
        // handleLevelQuit() {
        //     navigator.geolocation.clearWatch( this.watchTracker);
        //     this.$emit("next");
        // }
        gotoEndGame(qm) {
            //this.$emit("next");
            //console.log("clear watchtracker")
            qm.setSelected(true);

            navigator.geolocation.clearWatch( this.watchTracker);
            this.$router.push('/student/endgame/wait');

        },
   
        handleQuestionClose() {

            // check if this question was indeed answered
            let answers = this.$store.getters["game/answers"];
            let id = this.$store.getters["game/currentLocation"].id;

            if (answers[id] != "") {

                // we answered a question, update the stats
                //console.log("questionClose");
                this.sendLocationToMaster( this.user.marker.getLatLng()  );

                // make sure all markers are visible now that we answered at least one 
                if (this.markerGroup.getLayers().length === 1) {
                    // show the other questions when only one is visible
                    this.questionmarkers.forEach( (qm) => {
                        qm.marker.addTo(this.markerGroup);
                    });
                }
            }

            this.$store.dispatch("game/setCurrentLocationId",null);
                
            this.resetHeader();

            this.updateMarkers();
            this.checkForCompletion();


        },
        updateMarkers() {
           // this.logger.info('updateMarkers()');
            // check all locations on the map and see if something has changed in the results
            this.questionmarkers.forEach( (qm) => {
                qm.setResult( this.$store.getters['game/answers'][ qm.questionId ] );
            });
        },
        checkForCompletion() {
            // console.log("==check==")
            // are we done yet?

            if (this.$store.getters['game/roundcomplete']) {
                if (this.$store.getters["game/gamecomplete"]  ) {
                    //navigator.geolocation.clearWatch( this.watchTracker);

                    // stop the timer
                    this.$store.dispatch('clock/clearTimer');
                    // show the popup
                    this.$refs.popup.show("endgame-locations");
                    // show the endgame icons
                    this.drawEndgameMarker();

                    this.sendStatusToMaster("finished");
                }
                else {


                    this.$refs.popup.show("round-complete");

                    let newround = Number(this.$store.getters['game/currentRound']) + 1;
                    if (newround <  this.$store.getters['gamesettings/numberOfRounds']) {
                        this.$store.dispatch('game/setCurrentRound', newround);
                        this.startRound();
                    }
                }
            }
        },
        handleTimeout() {
            // close question
            this.$store.dispatch("game/setCurrentLocationId",null);
            this.$store.dispatch("setHeaderContent", {caption: "Plattegrond"} );

            let newround = Number(this.$store.getters['game/currentRound']) + 1;
            if (newround <  this.$store.getters['gamesettings/numberOfRounds']) {
                this.$refs.popup.show("round-timeout");

                this.$store.dispatch('game/setCurrentRound', newround);
                this.startRound();
            }
            else {
                this.$refs.popup.show("endgame-locations-timeout");
                // show the endgame icons
                this.drawEndgameMarker();
            }
        },
        drawMarkers(  ) {

            this.logger.info('drawMarkers()');

            
          //  console.log("draw markers")
            this.questionmarkers = [];

            this.markerGroup.clearLayers();

            // get the locations for the current round
            let selected_rounds = this.$store.getters["gamesettings/selected_rounds"];
            let ids_in_current_round = selected_rounds[ this.$store.getters["game/currentRound"]];

            let selected_location_ids = this.$store.getters['gamesettings/selected_locations'];
            let locations = this.$store.getters['gamesettings/locations'];

            // console.log(this.$store.getters["game/currentRound"]);

            // console.log(selected_rounds);
            // console.log(ids_in_current_round);

            // console.log(selected_location_ids)
            // console.log(locations);

            this.logger.info('selected_location_ids');
            this.logger.info(selected_location_ids);


            ids_in_current_round.forEach( (index) => {

                //console.log(index)
                // question in this round is selected by the begeleider 
                if (selected_location_ids.indexOf(index) >= 0) {
                    
                    //console.log(index)
                    let location = locations.find(location => location.id === index.toString());

                    if (location) {

                        //console.log(location.id)

                        let pos = location.latlng.split(",");
                        let qlatlng = this.convertLocationForTesting(
                            new L.LatLng(
                                Number(pos[0]),
                                Number(pos[1]) 
                            )
                        );                    

                        //let qm = new QuestionMarker(location.id, location.title, location.prize, location.error, qlatlng);
                        let qm = new QuestionMarker(location.id, qlatlng);
                        qm.setLabel(location.title, 'default');
                        qm.setFeedbackIcons( location.prize, location.error);

                        // save the markers for calculating the distance
                        this.questionmarkers.push(qm);

                        if (process.env.VUE_APP_GIT_BRANCH === "development" || process.env.VUE_APP_GIT_BRANCH === "staging" ) {
                            // selected question is clickable
                            qm.on('click', (qm) => {
                                this.debugid = qm.questionId;
                                //console.log(qm.questionId)
                                this.selectQuestionMarker(qm);
                            });
                        }
                        // measure distance to user
                        let dist = L.latLng(this.user.marker.getLatLng()).distanceTo(qm.marker.getLatLng() );

                        qm.setDistance(dist);
                    }
                }
            });

            //console.log(this.questionmarkers)

            console.log("round:" +  typeof this.$store.getters['game/currentRound']  )

            this.logger.info('number of questions/markers:' + this.questionmarkers.length);


            // first round: add only one random marker to the map
            if (this.$store.getters['game/currentRound'] == 0 && this.$store.getters['game/numAnswers'] === 0) {
                this.logger.info('get first nearby question');
                
                // sort the questionmarkers by distance
                this.questionmarkers.sort((a, b) => a.dist - b.dist );

                // get random one of the first 4 markers
                //console.log("pool:" + this.$store.getters["gamesettings/markerpool"])
                let max = Math.min(this.questionmarkers.length, this.$store.getters["gamesettings/markerpool"]);
                let r = Math.floor(Math.random() * max);
                this.logger.info('max pool:' + max);
                this.logger.info('selected question/marker:' + r);


                this.questionmarkers[r].marker.addTo(this.markerGroup);

                this.logger.info('=== map first question setup complete ===' );
            }
            else {
                // add all the markers to the map
                this.questionmarkers.forEach( (qm) => {
                    qm.marker.addTo(this.markerGroup);
                });
            }
        },
        drawEndgameMarker() {

            this.questionmarkers = [];
            this.markerGroup.clearLayers();

            let endgamelocation = this.$store.getters['gamesettings/selectedEndGameLocation'];

            // let pos = endgamelocation.latlng.split(",");
            // let qlatlng = new L.LatLng(
            //     Number(pos[0]) + this.offsetlat,
            //     Number(pos[1]) + this.offsetlng
            // );

            let pos = endgamelocation.latlng.split(",");
            let qlatlng = this.convertLocationForTesting(
                new L.LatLng(
                    Number(pos[0]),
                    Number(pos[1]) 
                )
            );                    

            let qm = new QuestionMarker(endgamelocation.id, qlatlng);
            qm.setLabel(endgamelocation.title, 'default');
            qm.isEndGameMarker = true;

            // we need this for the location check loop
            this.questionmarkers.push(qm);

            if (process.env.VUE_APP_GIT_BRANCH === "development" || process.env.VUE_APP_GIT_BRANCH === "staging" ) 
            {
                qm.on('click', () => {
                    this.gotoEndGame(qm);
                });
            }

            qm.marker.addTo(this.markerGroup);

            // endgamelocations.forEach( (location) => {
            //     if (location) {



            //     }
            // });            

        },          
        handleRouteNavigation() {
            //console.log("handle route navigation:" + this.$route.params.option)
            //console.log("now at:" + this.$route.path)
            if (this.$route.params.option === 'start'){
                this.checkIfWeCanStart();
            }
        },           
        setupLeafletMap: function () {
            // console.log("setup leaflet")

            // Log something
            // debug | info | warn | error
            this.logger.info('setupLeafletMap()');
            console.log('== setupLeafletMap()');

            console.log(this.logger)

            // for easier handling of all the markers
            this.markerGroup =  L.featureGroup();
            
            this.map = L.map("map",this.leafletOptions).setView(this.center, this.zoom);

            this.map.addLayer(this.markerGroup);
            //Actueel_ortho25
            //brtachtergrondkaartgrijs

            //console.log( process.env.VUE_APP_FIXED_LANGE_VOORHOUT )

            if (process.env.VUE_APP_FIXED_LANGE_VOORHOUT === "true" ) {
                L.tileLayer(
                    "./static/offline-map/{z}/{x}/{y}.png",
                    {
                        attribution:'Kaartgegevens &copy; Kadaster',
                        maxZoom: 18,
                    }
                ).addTo(this.map);
            }
            else {
                L.tileLayer(
                    this.$store.getters["gamesettings/mapprovider"] + "/EPSG:3857/{z}/{x}/{y}.png",
                    {
                        attribution:'Kaartgegevens &copy; Kadaster',
                        maxZoom: 18,
                    }
                ).addTo(this.map);
            }

            // BVB 02 11 2021: add no entrance sign marker
            let no_entrance_icon = L.divIcon({
                className: 'no-entrance-icon',
                iconSize: [25,25],
            });
            let no_entrance_pos = L.latLng(52.082242552561105, 4.310340251144391);

            let no_entrance_marker = L.marker(no_entrance_pos, {icon:no_entrance_icon});   
            
            no_entrance_marker.addTo(this.map);


            // BVB 18 10 2021 : do not get location at 
            // setup, 
            //this.getLocation();
            // but start the watcher immediately, we will grab a start location from it at gamestart
            this.watchLocation();
        },
        calculateMapBounds( fitBounds ) {
            //console.log("== calculateMapBounds");
            // debug | info | warn | error
            this.logger.info('calculateMapBounds()');
            console.log('== calculateMapBounds()');

            // use all the markers to calculate the bounds and zoom of this map, once
            let locations = this.$store.getters['gamesettings/locations'];            
            
            //console.log("- number of locations:" + locations.length);
            this.logger.info('- number of locations:' + locations.length);
            console.log('- number of locations:' + locations.length);

            let locationsGroup = L.featureGroup();
            //locationsGroup.addTo(this.map)

            locations.forEach( (location) => {

                if (location) {

                    let pos = location.latlng.split(",");
                    let qlatlng = this.convertLocationForTesting(
                        new L.LatLng(
                            Number(pos[0]),
                            Number(pos[1]) 
                        )
                    );                    

                    L.marker(qlatlng).addTo(locationsGroup); 
                }
            }); 


            if (locations.length > 0) {
                //console.log("- use locations to create gamebounds")
                this.logger.info('- use locations to create gamebounds');
                console.log('- use locations to create gamebounds');
                

                // for checking if students walk out of the location
                this.gamebounds = locationsGroup.getBounds().pad(.1);

                // for fitting the map
                let mapbounds = locationsGroup.getBounds().pad(.08);
                // only fix the bounds when we are testing at lange voorhout
                this.map.fitBounds( mapbounds,  {paddingBottomRight: [180,0]} );

                if (fitBounds) {
                    //console.log("- disable zooming and panning")
                    this.logger.info('- disable zooming and panning');
                    console.log('- disable zooming and panning');

                    // no zooming and panning for ya!
                    this.map.setMaxBounds( this.map.getBounds()  );

                    let zoom = this.map.getBoundsZoom( this.map.options.maxBounds );
                    this.map.setMinZoom( zoom );
                    this.map.setMaxZoom( zoom );
                    console.log("zoomlevel:" + zoom);

                }

            }

        },
        resetHeader() {
            let caption = 'Plattegrond <span style="color:black">&nbsp;-&nbsp;' + this.$store.getters['user/groupname']  + '</span>'
            this.$store.dispatch("setHeaderContent", {caption: caption} );
        },
        startRound() {
            
                this.logger.info('startRound()');

                // clear the old timer
                this.$store.dispatch('clock/clearTimer');

                // // start the new timer
                let roundtime = Math.max(.5,this.$store.getters['gamesettings/duration'] / this.$store.getters['gamesettings/numberOfRounds']);
                this.$store.dispatch('clock/startTimer', roundtime * 60);

//                let caption = 'Plattegrond <span style="color:black">ronde ' +  (Number(this.$store.getters['game/currentRound']) + 1) + ' van ' + this.$store.getters['gamesettings/numberOfRounds'] + '</span>';

                this.resetHeader();
                // let caption = 'Plattegrond <span style="color:black">&nbsp;-&nbsp;'  
                //     + this.$store.getters['user/groupname']  + '&nbsp;-&nbsp;</span>'
                //     //+ ' &nbsp;~ RONDE <span style="color:black">' +  (this.$store.getters['game/currentRound'] + 1) 
                //     //+ '/' + this.$store.getters['gamesettings/numberOfRounds'] 
                //     //+ 'ronde ' +  (Number(this.$store.getters['game/currentRound']) + 1) + ' van ' + this.$store.getters['gamesettings/numberOfRounds'] ;
                // this.$store.dispatch("setHeaderContent", {caption: caption} );

                this.sendStatusToMaster("round");

                // this.$store.getters['game/numberOfRounds']


                //console.log("start round:" + this.$store.getters['game/currentRound']  )
                this.drawMarkers();

                this.updateMarkers();
                this.checkForCompletion();
        },
        setupLocation(latlng) {
            //console.log("== setupLocation");
            this.logger.info('setupLocation(), ' + latlng);
            console.log('setupLocation(), ' + latlng);

            this.sendLocationToMaster(latlng);

            this.map.panTo(latlng);


            let atLangeVoorhout;

            if (process.env.VUE_APP_FIXED_LANGE_VOORHOUT === "true") {
                atLangeVoorhout = true;
            }
            else {
                let dist = L.latLng(latlng).distanceTo( this.center );
                atLangeVoorhout = (dist < 500);
            }

            //atLangeVoorhout = true;
            //console.log("- op lange voorhout:" + atLangeVoorhout);
            this.logger.info('op lange voorhout:' + atLangeVoorhout);
            console.log('op lange voorhout:' + atLangeVoorhout);

            //atLangeVoorhout = true;

            if (!atLangeVoorhout) {
                this.logger.info('niet op lange voorhout, gebruik locatie offset voor testen');
                console.log('niet op lange voorhout, gebruik locatie offset voor testen');
                // calculate lat/lng offset
                this.offsetlat = latlng.lat - this.center.lat;
                this.offsetlng = latlng.lng - this.center.lng;
               // console.log(this.offsetlat);
            }

            this.calculateMapBounds( atLangeVoorhout );

            // BVB 18 10 2021 moved creation of user icon to the watcher
            // // add icon for the user
            // this.user = new UserMarker(latlng);
            // //this.user.setMaster();
            // this.user.marker.addTo(this.map);

            // BVB 18 10 2021 moved this to setup
            // this.watchLocation();
            
            // BVB 18 10 2021 moved this to watchLocation
            //this.checkIfWeCanStart();

        },
        convertLocationForTesting(latlng) {
            //console.log(this.offsetlat + ":" + this.offsetlng)
            return new L.LatLng(
                latlng.lat + this.offsetlat,
                latlng.lng + this.offsetlng
            );
        },        
        getLocation() {

            // BVB 18 10 2021
            // not in use anymore, because we start the watcher earlier

            //console.log("== getLocation")
            this.logger.info('getLocation()');
            console.log('== getLocation()');

            console.log( this.$root.location )
            if (this.$root.location) {

                //console.log("- using root location (vwnw)")
                this.logger.info('using root (vwnw) location:' + this.$root.location);
                console.log('- using root (vwnw) location:' + this.$root.location);

                let location = this.$root.location;
                 let latlng = new L.LatLng(
                    location.coords.latitude,
                    location.coords.longitude
                )

                this.setupLocation( latlng );
            }
            else {
                // no location yet, get it now
                //console.log("- no root location, get geolocation")
                this.logger.info('- get geolocation');
                console.log('- get geolocation');

                navigator.geolocation.getCurrentPosition(
                    (location) => {

                        if (this.$root.data) {
                            this.$root.data.location = location;
                        }

                        let latlng = new L.LatLng(
                            location.coords.latitude,
                            location.coords.longitude
                        )
                       // console.log("- geolocation acquired")
                        this.logger.info('- geolocation acquired:' + latlng);
                        console.log('- geolocation acquired:' + latlng);

                        this.setupLocation( latlng );
                    },
                    (error) => {
                        console.error(`ERROR getting geolocation(${error.code}): ${error.message}`);
                        this.logger.error(`ERROR getting geolocation(${error.code}): ${error.message}`);

                        //this.logger.exportToArray()
                    }
                );
            }
        },
        checkIfWeCanStart() {
            console.log("==check if we can start==");
            console.log("got user:" + (this.user));
            console.log("got start:" + (this.$route.params.option === "start"));
            console.log("got code:" + ( this.$store.getters["gamesettings/code"]));
            //console.log("is started:" + this.isStarted)
            console.log("previously started:" + this.$store.getters["game/gamestarted"]);

            console.log("option:" + this.$route.params.option)


            this.logger.debug("check if we can start");
            this.logger.debug("got user:" + (this.user));
            this.logger.debug("got start:" + (this.$route.params.option === "start"));
            this.logger.debug("got code:" + ( this.$store.getters["gamesettings/code"]));
            //console.log("is started:" + this.isStarted)
            this.logger.debug("previously started:" + this.$store.getters["game/gamestarted"]);

            this.logger.debug("option:" + this.$route.params.option)



            // if (this.$route.params.option === "wait" && this.$store.getters["game/gamestarted"]) {
            //     // console.log("go from wait to start")
            //     this.$router.push("start");
            // }

            // to start, we need: a code, and a gps location
            if (this.user 
                    && (this.$route.params.option === "start") 
                    && this.$store.getters["gamesettings/code"])    {
                //this.isStarted = true;
                console.log("start with code:" + this.$store.getters["gamesettings/code"]);

                this.logger.debug("start with code:" + this.$store.getters["gamesettings/code"]);

                // use the current user location to setup the game field
                this.setupLocation( this.user.marker.getLatLng() );

                this.$store.dispatch("game/setGameStatus", "started" );
                this.sendStatusToMaster("started");

                this.$store.dispatch("game/initAnswers");

                this.$store.dispatch("game/retrieveGroupResults")
                .then(() => {

                    this.startRound();
                    //this.watchLocation();
                }) 

                // this.$store.dispatch("game/initAnswers");

                // this.startRound();
                // this.watchLocation();

                //this.$router.push('start-game');
            }
            else {
                console.log("-- NO start")
            }
        },
        centerMapToLocation(latlng) {
            this.map.panTo(latlng);
            //   var markerBounds = L.latLngBounds([latlng]);
            //   this.map.fitBounds(markerBounds);
        },
        selectQuestionMarker(qm) {
            // console.log("select marker ");
            // console.log(qm);

            this.questionmarkers.forEach( (qm) => {
                qm.setSelected(false);
            }); 
            qm.setSelected(true);

            this.$store.dispatch("game/setCurrentLocationId",qm.questionId)

            //this.$store.dispatch("user/setCurrentLocationId",qm.questionId)

            //this.question = qm.question;
        },
        hittestMarkers(latlng) {
            //console.log(this.questionmarkers);

            for (let i=0; i < this.questionmarkers.length; i++) {

                let qm = this.questionmarkers[i];
                
                //console.log(qm);

                // only hittest not answered locations
                if (!qm.isCompleted()) {

                    //console.log("-not completed");
                    // and when the marker is actually on the map
                    if (this.markerGroup.hasLayer(qm.marker)) {

                        //console.log("-on the map");

                        let dist = L.latLng(latlng).distanceTo(qm.marker.getLatLng() );

                        //console.log(qm)
                        // if (qm.questionId === "1" || qm.questionId === "4") {
                        //     console.log(qm.questionId + ", dist:" + dist + ", selected:" + qm.selected );
                        // }
                        // hit
                      //  console.log("hitd: " + this.hitDistance)
                        if (dist < this.$store.getters["gamesettings/hitdistance"] && !qm.selected) {
                           // console.log("SELECT " + qm.questionId)
                            //console.log("-close enough");
                            //console.log("endgame:" + qm.isEndGameMarker)
                            if (qm.isEndGameMarker) {

                                //console.log("-endgame marker");

                                this.gotoEndGame(qm);
                            }
                            else {
                                //console.log("-regular marker");
                                this.selectQuestionMarker(qm);
                            }
                            break;
                        }
                        else {
                        // console.log("nope")
                            //console.log("-far away");
                            // unselect selected marker when too far away
                            if (dist > this.$store.getters["gamesettings/hitdistance"] && qm.selected) {
                                //console.log("-deselect because it was selected");
                                qm.setSelected(false);
                            }
                        }
                    }
                }
            }
        },
        watchLocation() {
            //console.log("start watch")
            // watchPosition
            this.watchTracker = navigator.geolocation.watchPosition( (location) => {
                // let latlng = new L.LatLng(
                //     location.coords.latitude,
                //     location.coords.longitude
                // );

                let latlng = new L.LatLng(
                    location.coords.latitude,
                    location.coords.longitude
                )

                // console.log(latlng);
                // console.log(new L.LatLng(
                //         location.coords.latitude,
                //         location.coords.longitude
                //     ))

                // create a user icon when it is not there yet
                if (!this.user) {
                    // add icon for the user
                    this.user = new UserMarker(latlng);
                    this.user.marker.addTo(this.map);

                    this.hasLocation = true;

                    // check if we can start in case it was the absence of gps location preventing start
                    this.checkIfWeCanStart();
                }

                this.user.marker.setLatLng(latlng); 

               // console.log( this.$store.getters['game/currentLocationId'] )
                if (!this.$store.getters['game/currentLocationId']) {
                    // only test when we are not currently busy with a question
                    this.hittestMarkers(latlng);
                }

                let dist = L.latLng(latlng).distanceTo( this.center );
             //   console.log(dist)
                // only if we are in 10km distance because testing at home was troublesome
                if (dist < 300) {
                    if (this.gamebounds && !this.gamebounds.contains(latlng)) {

                        // BVB 27-02-2023, add check for popup object to prevent this error:
                        // TypeError this.watchTracker(src/components/student/MapScreen):  
                        // Cannot read properties of undefined (reading 'show')

                        // this popup is not always mounted when this error occurs because it can also
                        // happen when the mapScreen is not visible

                        // moved the vibrate in the if as well, so device only vibrates when the popup is visible
                        if (this.$refs.popup){
                            this.$refs.popup.show("out-of-bounds");
                            window.navigator.vibrate(400);
                        }
                    }
                }

                //this.$store.dispatch("socket/sendStatsToMasterToMaster",latlng,{root:true});
               // console.log("watchLocation");
                this.sendLocationToMaster(latlng);

            },
            (error) => {
                console.log(error);
            }, 
            this.gpsOptions);
        },
        sendStatusToMaster(status) {

           // console.log("score: " + this.$store.getters["game/score"])
            let msg = {
                student_action: "statusUpdate",
                payload: {
                    action: "gameUpdate",
                    status: status,
                    round: this.$store.getters['game/currentRound'],
                    groupname: this.$store.getters["user/groupname"],
                    socketid: this.$socket.client.id,
                    score: this.$store.getters["game/score"]
                }
            }
            let opts = SocketClientHelper.createMessageToAll(msg)
            this.$socket.client.emit('event',opts); 
        },        
        sendLocationToMaster(latlng) {

           // console.log("score: " + this.$store.getters["game/score"])
            let msg = {
                student_action: "statusUpdate",
                payload: {
                    action: "locationUpdate",
                    groupname: this.$store.getters["user/groupname"],
                    location: latlng,
                    socketid: this.$socket.client.id,
                    score: this.$store.getters["game/score"]
                }
            }
            let opts = SocketClientHelper.createMessageToAll(msg)
            this.$socket.client.emit('event',opts); 
        },
    },
    mounted() {
       //console.log("mount MapScreen")

        // this.logger = createLog({
        //     logName: 'grondwetloop-log',
        //     maxLogSizeInBytes: 500 * 1024 // 500KB
        // });
        //console.log()

        this.logger = new Loggerage("grondwetloop-log");
      //  this.logger.setSilence(false);

        let today = new Date().toLocaleDateString();
        let logdate = localStorage.getItem("grondwetloop-log-date");

        if (today != logdate || process.env.NODE_ENV === "development") {
            this.logger.clearLog();
            console.log("localstorage log cleared");
            localStorage.setItem("grondwetloop-log-date", today);
        }

        this.logger.debug("\n\n==== Mapscreen mounted ====");
        this.logger.debug("room: " + this.$store.getters["user/roomname"]);
        this.logger.debug("group: " + this.$store.getters["user/groupname"]);

        console.log("\n== Mapscreen mounted ====")


        // Export the log entries
        //const logEntries = this.logger.exportToArray();


        if (this.$store.getters['user/roomname'] === '' ) {
            this.$router.push("/");
        }

        // console.log(this.$route.params.option)

        this.$socket.client.on("message",(msg)=>{

            if (msg.student_action) {
                // score (and location) update from the other students
                switch(msg.student_action) {
                    // case "dimScreens" : {
                    //     this.$store.dispatch("game/dimScreen",msg.dimmed);
                    //     this.$store.dispatch("clock/pauseTimer",msg.dimmed);
                    //     break;             
                    // }
                    case "statusUpdate": {

                    // console.log(data.payload)

                        this.$store.dispatch('groups/updateGroup',msg.payload);
                    }
                }
            }
            else if (msg.master_action) {
              
                console.log("master action received in MapScreen:" + msg.master_action);

                switch(msg.master_action) {
                    case "dimScreens" : {
                        this.$store.dispatch("game/dimScreen",msg.dimmed);
                        this.$store.dispatch("clock/pauseTimer",msg.dimmed);
                        break;
                    }
                    case "startGame" : {
                        console.log("START from map screen")
                        this.$store.dispatch("gamesettings/decodeGameInfo",msg.code)
                        .then( () => {
                            // game settings decoded, start the game
                            // BVB 28 02 2023 prevent error
                            // NavigationDuplicated: Avoided redundant navigation to current location
                            // this occurs because this socket event listener exists in multiple locations, which is clearly
                            // not a good idea
                            // I left the listeners as is and just check if we are already at the route path
                            // A better option would be to centralize the socket listeners, but that would be a 
                            // substantial rewrite
                            if (this.$route.path !='/student/map/start' ){
                                this.$router.push('/student/map/start')
                            }  
                            else {
                                this.checkIfWeCanStart();
                            }                      
                        });
                        break;
                    }
                    case "startEndgame" : {
                        navigator.geolocation.clearWatch( this.watchTracker);
                        console.log("now at:" + this.$route.path)
                        // BVB 28 02 2023 prevent error
                        // NavigationDuplicated: Avoided redundant navigation to current location: "/student/endgame/instructions".
                        // this occurs because this socket event listener exists EndGameScreen as well in MapScreen
                        // and is active in both when the student came fom the MapScreen
                        // since it is possible to jump to the EndGameScreen without passing MapScreen
                        // I left the listener on both screens and just check if we are already at the route path
                        // A better option would be to centralize the socket listeners, but that would be a 
                        // substantial rewrite
                        if (this.$route.path !='/student/endgame/instructions' ){
                          this.$router.push('/student/endgame/instructions')
                        }                        
                        break;
                    }                    
                    case "requestGroupname": {
                        if (this.user) {
                            //console.log("requestGroupname");
                            this.sendLocationToMaster( this.user.marker.getLatLng()  );
                        }
                        break;
                        //this.handleStudentLocationUpdate(data.payload);
                    }
                }
            }
        });

        // this.$store.dispatch("game/getLocations")
        // .then(() => {
        this.setupLeafletMap();
        // })
        // .catch(error => {
        //     console.log(error);
        // });

        //this.$store.dispatch("game/getLeaderBoard");
        this.$store.dispatch("game/setCurrentLocationId",null);
        let caption = 'Plattegrond <span style="color:black">ronde ' +  (Number(this.$store.getters['game/currentRound']) + 1) + ' van ' + this.$store.getters['gamesettings/numberOfRounds'] + '</span>';

        // this.$store.getters['game/numberOfRounds']

        this.$store.dispatch("setHeaderContent", {caption: caption} );
        this.$store.dispatch("setFooterVisibility", false);

    },
    watch: {
         $route() {
            //console.log(from + ":" + to)
            // react to route changes...
            this.handleRouteNavigation();
        },
        // whenever question changes, this function will run
        // code: function() {
        //     //console.log(code)

        //     // code change, redraw game field
        //     this.checkIfWeCanStart();

        // },
        countdownComplete: function (isComplete) {
            //console.log("watch:" + oldvalue + ":" + isComplete)
            if (isComplete) {
                // quit the watcher

                // console.log("countdown complete")
                // this.timeOut = true;
                this.handleTimeout();

            }
        }
    },    
    computed: {
        countdownComplete() {
            return this.$store.getters["clock/countdownComplete"];
        },
        // code() {
        //      return this.$store.getters["game/code"];
        // }
  },
};
</script>


<style scoped>
.page {
    position: relative;
    width: 100vw;
    height: 90vh;
    top:10vh;
    background-color: var(--green-middle-color);
}

.location-waitscreen {
    position: absolute;
    width: 100vw;
    height: 90vh;
    z-index:10;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.location-waitscreen .backdrop {
    position: absolute;
    background-color: var(--purple-middle-color);
    opacity: .4;
    width:100%;
    height:100%;

}

.location-waitscreen .message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30vw;
    height:25vh;
    background-color: var(--green-middle-color);
    z-index:4;
}

.map-panel {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.ranking-panel {
    position: absolute;
    z-index:3;
    top: calc(158px / var(--scale) );
}

.question-panel {
    position: absolute;
    z-index:4;
}



.map {
    width: 100%;
    height: 100%;
}


.roundquitbutton {
    position: absolute;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%); 

    /* top: 0;
    left:0;
    right:0;
    bottom:0; */
    z-index: 2;
}
</style>