<template>
  <div class="page">    

    <div class="content" >
          <div class="panel-left" >
                
            <div class="panel-top">
              <span class="title">{{$t(prefix + '-caption')}}</span>
            </div>
            <div class="panel-bottom">
              <div class="instruction" v-html="$t(prefix+ '-instructions')">
            </div>

          </div>
      </div>
      <div class="panel-buttons">
        <app-button v-if="next" class="button" ref="nextbutton" color="purple" bevel="true" :caption="$t('button-next')"   @click="handleNext" />
        <app-button v-if="prev" class="button prevbutton" ref="nextbutton" color="purple" bevel="false" :caption="$t('button-prev')"   @click="handlePrev" />
      </div>

    </div>
  </div>
</template>

<script>
  import AppButton from  "@/components/ui/AppButton.vue";

  export default {
    components: {
      AppButton
    },
    props:["prefix","next","prev"],
    computed: {
    },
    methods: {
      handleNext() {
        this.$router.push(this.next);
      },
      handlePrev() {
        this.$router.push(this.prev);
      }
    },
    beforeDestroy() {
     // console.log("before destroy InstructionScreen")
      this.$socket.client.removeAllListeners("message");
    },
    mounted() {
     // console.log("mount InstructionScreen")
      //this.$store.dispatch("setHeaderContent", {caption: "Instructies " + this.$t(this.prefix + '-caption').toLowerCase() } );
      this.$store.dispatch("setHeaderContent", {caption: "Instructies "  } );

      this.$socket.client.on("message",(msg)=>{

            if (msg.master_action) {
               console.log("master action received in Instructionscreen:" + msg.master_action);

                switch(msg.master_action) {
                    case "dimScreens" : {
                        this.$store.dispatch("game/dimScreen",msg.dimmed);
                        this.$store.dispatch("clock/pauseTimer",msg.dimmed);
                        break;
                    }
                    case "startGame" : {
                        //console.log("START from instruction screen")
                        this.$store.dispatch("gamesettings/decodeGameInfo",msg.code)
                        .then( () => {
                            // game settings decoded, start the game
                          // BVB 28 02 2023 prevent error
                          // NavigationDuplicated: Avoided redundant navigation to current location
                          // this occurs because this socket event listener exists in multiple locations, which is clearly
                          // not a good idea
                          // I left the listeners as is and just check if we are already at the route path
                          // A better option would be to centralize the socket listeners, but that would be a 
                          // substantial rewrite
                          if (this.$route.path !='/student/map/start' ){
                            this.$router.push('/student/map/start')
                          }

                        });
                        break;
                    }
                    case "startEndgame" : {
                        // BVB 28 02 2023 prevent error
                        // NavigationDuplicated: Avoided redundant navigation to current location
                        // this occurs because this socket event listener exists in multiple locations, which is clearly
                        // not a good idea
                        // I left the listeners as is and just check if we are already at the route path
                        // A better option would be to centralize the socket listeners, but that would be a 
                        // substantial rewrite
                        if (this.$route.path !='/student/endgame/instructions' ){
                          this.$router.push('/student/endgame/instructions')
                        }
                        break;
                    }                    
                }
            }
      });
      //console.log(this.prefix)
    }
  }
</script>

<style scoped>
  
.page {
    background-color: var(--green-middle-color);
}

.content {
    position: relative;
    margin-top: 60px;
    width:100vw;
    height: 90vh;
    display: flex;
    flex-direction: row;
}

.panel-left {
  width: 73vw;
  height: 75vh;

  font-family:'Fira Sans Bold';
  font-size: calc(35px / var(--scale));
  color: var(--yellow-dark-color);

}

.panel-top {
    position: relative;
    margin-top: calc(160px / var(--scale));    
    margin-left:calc(183px / var(--scale));
    /* background-color: green; */
}

.panel-bottom {
    margin-top: 20px;
    margin-left:calc(183px / var(--scale));
    /* background-color: blue; */

}


.panel-buttons {
    height: 100vh;
    width:27vw;
    /* margin-top:150px; */
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}


.panel-buttons .button {
  margin-top: 150px;
  margin-right:60px;
  margin-left: 50px;
}

.panel-buttons .prevbutton {
  margin-top: 30px;
}

.title {

  font-family: 'Fira Sans Bold';
  font-size: calc(33px / var(--scale) );
  line-height: 30px;

  text-transform: uppercase;

  color:var(--purple-dark-color);
  border-bottom: 3px solid var(--purple-dark-color);
}

.instruction {
    font-family: 'Fira Sans Regular';
    color:var(--dark-text-color);
    font-size: calc(29px / var(--scale) );
    width: calc(800px / var(--scale) );

}

  
</style>