class SocketClientHelper {

    static createMessageToMaster(msg) {
        const options = 
        {
            event: "message",
            target: 0b0100, 
            args: msg
        };

        return options;
    }

    static createMessageToStudents(msg) {
        const options = 
        {
            event: "message",
            target: 0b0010, 
            args: msg
        };

        return options;
    }

    static createMessageToAll(msg) {
        const options = 
        {
            event: "message",
            target: 0b0111, 
            args: msg
        };

        return options;
    }
}

export default SocketClientHelper;