<template>
    <li :class="['answer', (feedback != '') ? feedback + '-bg' : '' ]" >
        <div class="drag-handle">
            <img src="~@/assets/draghandle.svg" />
        </div>
        <div :class="['answer-text', (feedback != '') ? feedback + '-color' : '' ]">
            {{ text }} 
        </div>
    </li>
</template>

<script>

export default {
    name: "DragDropAnswer",
    components: {
    },    
    props: {
        order: {
            type: Number,
            required: true
        },    
        text : {
            type: String,
            required: true
        }
    },
    data() {
        return {
            feedback: ''
        };
    },
    methods: {
        setFeedback(f) {
            //console.log("feedback:" + f)
            this.feedback = f;
        },
        reset() {
            this.setFeedback('');
        }
    },
    mounted() {


    },
    computed: {

    },
};
</script>


<style scoped>


.answer {
    margin-bottom: calc(24px / var(--scale) );  
    /* margin-left: calc(20px / var(--scale) );  */
    display:flex;
    /* align-items: center; */
    background-color: rgba(255,255,255,0.5);
    /* border:1px solid black; */
}


.drag-handle {
    padding-top: calc(2px / var(--scale) );
    padding-left: calc(8px / var(--scale) );

    width: calc(30px / var(--scale) );
    /* background-image : url('~@/assets/draghandle.svg');
    background-size: cover; */
}

.drag-handle img {
    width: calc(14px / var(--scale) );
    height: calc(11px / var(--scale) );
}

.answer-text {
    width: calc(430px / var(--scale) );
    height: calc(71px / var(--scale) ); 
    margin-left: calc(20px / var(--scale) );   
    padding-top: calc(3px / var(--scale) ); 
    /* padding-right: calc(20px / var(--scale) );  */
    text-align: left;
    font: calc(24px / var(--scale) )/calc(32px / var(--scale) ) Fira Sans Regular;
    letter-spacing: 0px;
    color:var(--purple-dark-color);
    /* border:1px solid black; */
}

.correct-color {
    color:#58A333;
}

.incorrect-color {
    color:#D60D41;
}

.correct-bg {
    background-color:#ffffff;
}

.incorrect-bg {
    background-color:#ffffff;
}



</style>