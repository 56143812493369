<template>
    <div class="ranking" @click="toggleLeaderBoard">
        <div class="caption">{{$t('caption-ranking')}}</div>
        <div class="shield">
            <div class="number">{{ this.ranking  }}</div>
            <img :src="getRankingShield()" />
        </div>
        <div class="arrow">{{this.showleaderboard ? "&#9660;" : "&#9650;"}}</div>
        <div class="score" v-if="(this.showleaderboard)">
            <ol>
                <li class="board-entry"
                    v-for="(group,index) in this.$store.getters['groups/leaderboard']"  
                    :class="boldOrNot(group.groupname)? 'selected': ''"
                    :key="index" 
                >
                    <span>{{group.groupname}}</span>  {{group.score}} punten
                </li>
            </ol>
        </div>
    </div>
</template>

<script>


export default {
    name: "RankingPanel",
    components: {
    },    
    data() {
        return {
            showleaderboard: false,
            ranking: 3,
        };
    },
    methods: {
        boldOrNot(groupname) {
            return (groupname === this.$store.getters['user/groupname']);
        },
        toggleLeaderBoard() {
            this.showleaderboard = !this.showleaderboard;
        },
        getRankingShield() {
            let shield = "./static/images/rankings/ranking.png";
            if (this.ranking > 0 && this.ranking < 4) {
                shield = "./static/images/rankings/ranking_" + this.ranking + ".png";
            }
            return shield;
        }
    },
    mounted() {
        

    },
     watch: {
        leaderboard: function (lb) {
            this.ranking = lb.findIndex( group => group.groupname === this.$store.getters['user/groupname'] ) + 1;
        }
    },
    computed: {
        leaderboard() {
            return this.$store.getters["groups/leaderboard"];
        },
    },
};
</script>


<style scoped>

.ranking {
    width: calc(293px / var(--scale) );
    padding-top: calc(20px / var(--scale) );
    padding-bottom: calc(20px / var(--scale) );
    padding-left: calc(30px / var(--scale) );

    /* height: calc(143px / var(--scale) ); */
    background-color: rgba(255,255,255,0.65);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    
}


.caption {
    font: calc(30px / var(--scale) )/calc(21px / var(--scale) ) Fira Sans Medium;
    font-size: calc(30px / var(--scale) );
    color: var(--purple-dark-color);
    text-transform: uppercase;
    float:left;
}

.arrow {
     color: var(--purple-dark-color);
     position: absolute;
     top: calc(100px / var(--scale) );
     left: calc(100px / var(--scale) );
}

.shield {
    width: calc(76px / var(--scale) );
    height: calc(105px / var(--scale) );
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
}

.shield img {
    position: absolute;
    width: calc(76px / var(--scale) );
    height: calc(105px / var(--scale) );
    z-index: 1;
}

.shield .number {
    position: absolute;
    z-index:2;
    font: calc(60px / var(--scale) )/calc(59px / var(--scale) ) Fira Sans Medium;
    font-size: calc(60px / var(--scale) );
    color: white;
}

ol {
    margin:0;
    padding: 0;
    margin-top: calc(20px / var(--scale) );

}

.board-entry {
    color: var(--purple-dark-color);
    font: calc(20px / var(--scale) )/calc(26px / var(--scale) ) Fira Sans Regular;
    /* font-size: calc(20px / var(--scale) ); */
}


.board-entry span {
    float: left;
    width: 100px;
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
  /* background-color: red; */
}

.selected {
    font-weight: bold;
}

</style>