<template>

<div class="starter">
        <div class="panel-left">
            <div class="title">{{$t('selfstart-instructions-caption')}}</div>

            <div class="instruction">
                {{$t('selfstart-instructions')    }}
                <input :value="code" ref="code" class="inputfield" type="text" maxlength="6" @change="handleInputChange"  @input="handleInputChange"/>
            </div>
        </div>
        <div class="panel-right">
            <app-button class="button"  ref="nextbutton" color="yellow" bevel="true" :caption="$t('button-next')"  @click="handleSubmit" />
            <app-button class="button"  ref="prevbutton" color="yellow" bevel="false" :caption="$t('button-prev')"  @click="handleCancel" />
        </div>
      </div>
</template>

<script>
import AppButton from  "@/components/ui/AppButton.vue";

export default {
    name: "SelfStartPanel",
    components: {
        AppButton,
    },
  data() {
    return {
        code:'', 
        showSelfStart: true
    }
  },
  methods: {
      handleInputChange({ target }) {
        target.value = target.value.toUpperCase();
        this.code = target.value;
        this.$refs.nextbutton.enabled( (target.value.length > 4) );
      },
      handleCancel() {
        // this.showstarter = false;
        this.$emit("hide");

      },
      handleSubmit() {
        if(this.$route.name === "EndGame") {
          if (this.code === "VOGELS") {
             this.$router.push('instructions');
             this.$emit("hide");
          }
        }
        else {
          console.log("START from selfstart panel")
          this.$store.dispatch("gamesettings/decodeGameInfo",this.code)
          .then( () => {
              // game settings decoded, start the game
              // BVB 28 02 2023 prevent error
              // NavigationDuplicated: Avoided redundant navigation to current location
              // this occurs because this socket event listener exists in multiple locations, which is clearly
              // not a good idea
              // I left the listeners as is and just check if we are already at the route path
              // A better option would be to centralize the socket listeners, but that would be a 
              // substantial rewrite
              if (this.$route.path !='/student/map/start' ){
                this.$router.push('/student/map/start')
              }
          });
          this.$emit("hide");
        }


        // let activity = this.activities.find( activity => activity.tag === this.choice );

        // if (this.$refs.code.value.toLowerCase() === activity.code) {
        //   this.$store.dispatch('userdata/initActivityData', activity.tag);
        //   this.$store.dispatch('screen/showScreen', activity.tag);     
        // }
        // else {
        //   this.$refs.code.focus(); 
        // }
      },      
  },
  computed: {
  }
};

</script>


<style scoped>

.starter {
    position: absolute;
    top: calc(170px / var(--scale) );
    background-color: var(--purple-dark-color);
    /* border:1px solid red; */
    width:100vw;
    height: 292px;

    display:flex;
    /* flex-direction: column; */
    z-index:11;


  /* display:none; */
}


.panel-left {
    /* background-color: blue; */
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* align-items: center; */
    width:75vw;
    padding-left: calc(200px / var(--scale) );;
    /* background-color: white; */
    /* padding-left: 80px;      */
}


.panel-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:flex-end;
  margin-right: 50px;
  margin-top: 40px;

  /* justify-content: space-between; */
}   

.title {
  /* font-weight: bold; */
  font-family: 'Fira Sans Bold';
  font-size: 32px;
  width: calc(600px / var(--scale) );
  color:var(--light-text-color);
  /* margin-bottom: 40px; */
  /* background-color: red; */
}

.instruction {
  /* font-weight: bold; */
  font-family: 'Fira Sans Bold';
  font-size: 23px;
  color:var(--light-text-color);
  margin-top: -20px;
  /* background-color: blue; */
}


  .inputfield {
    height: 50px;
    width: 185px;
    padding: 0 5px;
    font-family: 'Fira Sans Bold';
    font-size: 50px;
    font-weight: bold;
    margin-left: 30px;
  }

  .button {
    margin-bottom: 50px;
  }

</style>
