<template>
    <div class="panel" :class="color" >

        <div class="content">
            <img class="icon" src="~@/assets/shield-icon.png" @click="$emit('click')"/>

            <div class="title">{{$t('message-game-wait')}}</div>
            <div class="instruction">{{$t('hint-game-wait')}}</div>
        </div>
       
    </div>
</template>

<script>

  export default {
   // name: 'activity-setup',
    // data() {
    //   return {
    //     roomname : null
    //   }
    // },
    props: ['color'],
    components: {
    },
    computed: {
    
    },
    //props:["instruction","title","button_next"],
    methods: {
    },
    data() {
      return {
      }
    },
    destroyed() {
        // window.cancelAnimationFrame(this.id);
    },
    mounted() {
    }
  }
</script>

<style scoped>

.panel {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: var(--green-middle-color);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:10;
}

.panel.blue {
     background-color: var(--blue-middle-color);
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}


.icon {
  width:140px;
  margin-bottom: 30px;
}
.title {
  margin-bottom:8px;
  font:  calc(40px / var(--scale) )/ calc(35px / var(--scale) ) Fira Sans Bold;
  color:var(--light-text-color);
  text-transform: uppercase;

}

.instruction {
  font: calc(25px / var(--scale) )/ calc(35px / var(--scale) ) Fira Sans Bold;
  color:var(--dark-text-color);
}


</style>