<template>
  <div class="page">    
    <div v-if="this.$route.params.option==='instructions'" class="content" >
          <div class="panel-left" >
                
            <div class="panel-instructions">
              <span class="title">{{$t('endgame-caption')}}</span>

              <div class="instruction" v-html="$t('endgame-instructions')">

              </div>
            </div>

          </div>
          <div class="panel-right">
            <app-button class="button" ref="nextbutton" color="purple" bevel="true" :caption="$t('button-next')"  @click="$router.push('/student/endgame/start')" />
          </div>
      </div>
      <transition name="fade">
        <iframe v-show="this.$route.params.option=='start'" ref="endgame" class="externalsite-iframe" src="./static/endgame/index.html" scrolling="no"  ></iframe>
      </transition>
      <!-- <transition name="fade"> -->
      <shield-panel  v-if="this.$route.params.option === 'shield'"  />

      <game-wait v-if="(this.$route.params.option === 'wait')" color="blue" @click="(showSelfStart = true)" />
      <self-start-panel v-if="(this.showSelfStart)" @hide="showSelfStart=false" />
      <timed-popup-panel ref="popup" class="popup-panel" @close="handlePopupClose" />

      <!-- </transition> -->
  </div>
</template>

<script>
  import AppButton from  "@/components/ui/AppButton.vue";
  import ShieldPanel from "@/components/student/ShieldPanel/ShieldPanel.vue";
  import GameWait from  "@/components/student/GameWait.vue";
  import SelfStartPanel from "@/components/student/SelfStartPanel.vue";
  import TimedPopupPanel from "@/components/ui/TimedPopupPanel.vue";
  
  import SocketClientHelper from "@/helpers/SocketClientHelper";

  export default {
    name: "EndGameScreen",
    data() {
        return {
            gameIsLoaded: false,
            gameIsStarted: false,
            gameIsReady: false,
            readyToPlay: false,
            showSelfStart: false,
            currentPlayer: 0,
        };
    },
    components: {
      AppButton,
      ShieldPanel,
      GameWait,
      SelfStartPanel,
      TimedPopupPanel,

    },
    updated: function () {
      if (this.$route.params.option === 'start'){
        this.$refs.endgame.onload = () => {
          this.$refs.endgame.contentWindow.setParent( this );
        }
      }
    },
    methods: {
      handleRouteNavigation() {
        if (this.$route.params.option === 'start'){
          this.gameCheck();
        }
      },
      gameCheck() {
          if (this.gameIsLoaded &&  this.gameIsReady && this.$route.params.option === 'start') {
                this.$store.dispatch("game/retrieveGroupResults")
                .then(() => {
                  this.startPlayer();
                  //this.$refs.popup.show("endgame-first-player");
                }) 
          }
      },
      startPlayer(){

            let status = "";
            //console.log("player:" + this.currentPlayer)
            if (this.currentPlayer === 0) {
              this.$store.dispatch('clock/startTimer',  this.$store.getters["gamesettings/endgameplayertime"] );
              this.$refs.endgame.contentWindow.updateScore( this.$store.getters["game/score"] );
              this.$refs.endgame.contentWindow.startGame();

              status = "started";

            }
            else {
              this.$store.dispatch('clock/startTimer',  this.$store.getters["gamesettings/endgameplayertime"] );
              this.$refs.endgame.contentWindow.resumeGame( this.currentPlayer );

              status = "resumed";

            }

            let msg = {
                  student_action: "statusUpdate",
                  payload: {
                      action: "endgameUpdate",
                      status: status,
                      groupname: this.$store.getters["user/groupname"],
                      score: this.$store.getters["game/score"],
                      player: this.currentPlayer,
                      socketid: this.$socket.client.id,
                  }
            }

            console.log(msg)
           // send these stats to the master
            let opts = SocketClientHelper.createMessageToAll(msg)
            this.$socket.client.emit('event',opts); 
      },
      playerFinished() {
        // next player
        this.currentPlayer += 1;

       // console.log("new player:" + this.currentPlayer)

        if (this.currentPlayer > 2 ){
          // we are done
          this.$refs.endgame.contentWindow.timesUp();
        }
        else {
          this.$refs.endgame.contentWindow.pauseGame();
        }
      },
      popupNextPlayer( score ) {
        // show popup next player
        this.$store.dispatch("game/setScore",score)
        this.$refs.popup.show("endgame-next-player");
      },
      handlePopupClose() {
          this.$refs.popup.hide();
          this.startPlayer();
      },
      // this callback is called when the game in the iframe has finished setting up
      gameReady() {
        this.gameIsReady = true;
        this.gameCheck();
      },
      gameOver(score) {
        //console.log(result)
        this.$store.dispatch("clock/clearTimer");

        // save the new score
        this.$store.dispatch("game/setScore",score)
        .then(() => {
          this.$store.dispatch("game/setEndgameComplete",true)
          //this.state = 'shield';
          this.$router.push('/student/endgame/shield')

          // send these stats to the master
          let msg = {
              student_action: "statusUpdate",
              payload: {
                  action: "endgameUpdate",
                  status: "finished",
                  player: 0, // just to be complete, does not do anything
                  groupname: this.$store.getters["user/groupname"],
                  score: this.$store.getters["game/score"]
              }
          }
          let opts = SocketClientHelper.createMessageToAll(msg)
          this.$socket.client.emit('event',opts); 

        })
        .catch(error => {
          // for testing, so that we can test the endgame without logging in
          this.$store.dispatch("game/setEndgameComplete",true);
          
          //this.state = 'shield';
          this.$router.push('/student/endgame/shield')
          console.log(error)
        })
      }
    },
    watch: {
        // whenever question changes, this function will run
        $route() {
            //console.log(from + ":" + to)
            // react to route changes...
            this.handleRouteNavigation();
        },
        countdownComplete: function (isComplete) {
           //console.log("watch:" + oldvalue + ":" + isComplete)
            if (isComplete) {
                // quit the watcher
                //navigator.geolocation.clearWatch( this.watchTracker);

                //this.$emit("next")
                //this.$refs.endgame.contentWindow.timesUp();
                this.playerFinished();
            }
        }
    },  
    computed: {
        countdownComplete() {
            return this.$store.getters["clock/countdownComplete"];
        }
    },
    mounted() {
        if (this.$store.getters['user/roomname'] === '' ) {
            this.$router.push("/");
        }

        this.$store.dispatch("game/setGameStatus", "endgame" );

        console.log(this.$socket.client.listeners("message")); //

        this.$socket.client.on("message",(msg)=>{
           // console.log(msg);
           console.log("master action received in EndGamescreen:" + msg.master_action);

            if (msg.master_action) {
                switch(msg.master_action) {
                    case "dimScreens" : {
                        this.$store.dispatch("game/dimScreen",msg.dimmed);
                        this.$store.dispatch("clock/pauseTimer",msg.dimmed);
                        break;
                    }
                    case "startEndgame" : {
                        console.log("now at:" + this.$route.path)
                        // BVB 28 02 2023 prevent error
                        // NavigationDuplicated: Avoided redundant navigation to current location: "/student/endgame/instructions".
                        // this occurs because this socket event listener exists EndGameScreen as well in MapScreen
                        // and is active in both when the student came fom the MapScreen
                        // since it is possible to jump to the EndGameScreen without passing MapScreen
                        // I left the listener on both screens and just check if we are already at the route path
                        // A better option would be to centralize the socket listeners, but that would be a 
                        // substantial rewrite
                        if (this.$route.path !='/student/endgame/instructions' ){
                          this.$router.push('/student/endgame/instructions')
                        }
                        break;
                    }
                    case "requestGroupname": {
                        if (this.user) {
                            this.sendStatsToMaster( this.user.marker.getLatLng()  );
                        }
                        break;
                    }
                }
            }
        });

        let caption = 'Grondwetloop <span style="color:black">eindspel</span>';

        //this.$refs.nextbutton.enabled( true );
        // this.$store.getters['game/numberOfRounds']

        this.$store.dispatch("setHeaderContent", {caption: caption} );

        // this callback is called when the page is loaded in the iframe
        this.$refs.endgame.onload = () => {
          this.$refs.endgame.contentWindow.setParent( this );
          this.gameIsLoaded = true;
          this.gameCheck();
        }
    }
  }
</script>

<style scoped>
  
.page {
   background-color: var(--yellow-dark-color);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.content {
    position: relative;
    margin-top: 60px;
    width:100vw;
    height: 90vh;
    display: flex;
    flex-direction: row;
    background-color: var(--blue-middle-color);
}

.panel-left {
  width: 73vw;
  height: 75vh;

  font-family:'Fira Sans Bold';
  font-size: calc(35px / var(--scale));
  color: var(--yellow-dark-color);
  display: flex;
  align-items: center;
  justify-content: center;

}

.panel-instruction {

}

.title {
  font-family: 'Fira Sans Bold';
  font-size: calc(23px / var(--scale) );
  line-height: 30px;

  color:var(--purple-dark-color);
  border-bottom: 3px solid var(--purple-dark-color);
  text-transform: uppercase;
}

.instruction {
    font-family: 'Fira Sans Regular';
    color:var(--dark-text-color);
    font-size: calc(26px / var(--scale) );
    width: calc(729px / var(--scale) );
    margin-top: calc(60px / var(--scale) );
}

.panel-right {
    width:27vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.panel-right .button {
  margin-top:  calc(-130px / var(--scale) );
}

.externalsite-iframe {
  position: absolute;
  margin:0; 
  padding:0; 
  border:none; 
  overflow:hidden;

  border: 0; 
  width: 100%; 
  height: 100%;
} 

</style>