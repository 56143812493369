<template>
    <li :class="['answer', isSelected? 'answer-selected' : '' ]"  @click="handleSelect">
        <div :class="['answer-letter', (feedback != '') ? feedback + '-bg' : '']">
            {{ id }} 
        </div>
        <div :class="['answer-text', (feedback != '') ? feedback + '-color' : '' ]">
            {{ text }} 
        </div>
    </li>
</template>

<script>

export default {
    name: "MultipleChoiceAnswer",
    components: {
    },    
    props: {
        id: {
            type: String,
            required: true
        },    
        text : {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isSelected: false,
            isEnabled: true,
            feedback: ''
        };
    },
    methods: {
        setSelected(bool) {
            this.isSelected = bool;
            this.setFeedback('');
            //console.log("selected:" + bool)
        },
        setFeedback(f) {
            //console.log("feedback:" + f)
            this.feedback = f;
        },
        setEnabled(bool) {
            this.isEnabled = bool;
        },
        handleSelect () {
            if (this.isEnabled) {
                this.setSelected( !this.isSelected )
                this.$emit("change",this);
            }
        },
        reset() {
            this.setSelected(false);
            this.setEnabled(true);
            this.setFeedback('');
        }
    },
    mounted() {


    },
    computed: {

    },
};
</script>


<style scoped>


.answer {
    margin-bottom: calc(24px / var(--scale) );  
    /* margin-left: calc(20px / var(--scale) );  */
    display:flex;
    align-items: center;
    /* border:1px solid black; */
}

.answer-selected {
    background-color: white;
}

.answer-letter {
    width: calc(59px / var(--scale) );
    height: calc(59px / var(--scale) );    
    margin-left: calc(8px / var(--scale) ); 
    background-color: var(--purple-dark-color);
    text-align: center;
    font: calc(30px / var(--scale) )/calc(59px / var(--scale) ) Fira Sans Bold;
    color: #FFFFFF;
    text-transform: uppercase;
}

.answer-text {
    width: calc(430px / var(--scale) );
    height: calc(71px / var(--scale) ); 
    margin-left: calc(20px / var(--scale) );   
    padding-top: calc(3px / var(--scale) ); 
    /* padding-right: calc(20px / var(--scale) );  */
    text-align: left;
    font: calc(24px / var(--scale) )/calc(32px / var(--scale) ) Fira Sans Regular;
    letter-spacing: 0px;
    color:var(--purple-dark-color);
    /* border:1px solid black; */
}

.correct-color {
    color:#58A333;
}

.incorrect-color {
    color:#D60D41;
}

.correct-bg {
    background-color:#58A333;
}

.incorrect-bg {
    background-color:#D60D41;
}



</style>