<template>
    <div class="panel" >

        <div class="content">
            <img class="icon" src="~@/assets/shield-icon.png" />

            <div v-if='(this.$store.getters["game/gamestarted"])' class="title">{{$t('message-game-resume')}}</div>
            <div v-else class="title">{{$t('message-game-start')}}</div>


            <div class="instruction">
              code: {{ this.$store.getters['gamesettings/code'] }} 

               <br/>{{ this.$store.getters['gamesettings/numberOfRounds'] }} ronde(s)

              <br/>{{ this.$store.getters['gamesettings/duration'] }} minuten
              <br/>{{ this.$store.getters['gamesettings/selectedLevel'].caption }}
              <br/>eindspel {{ this.$store.getters['gamesettings/selectedEndGameLocation'].caption }}               
            </div>
            <app-button v-if='(this.$store.getters["game/gamestarted"])' class="button" color="purple" bevel="true" :caption="$t('button-game-resume')"  @click="handleSubmit" />
            <app-button v-else class="button" color="purple" bevel="true" :caption="$t('button-game-start')"  @click="handleSubmit" />

            <app-button v-if='(!this.$store.getters["game/gamestarted"])' class="button-prev" color="purple" bevel="false" :caption="$t('button-prev')"  @click="handlePrev" />

        </div>
       
    </div>
</template>

<script>
  import AppButton from  "@/components/ui/AppButton.vue";

  export default {
   // name: 'activity-setup',
    // data() {
    //   return {
    //     roomname : null
    //   }
    // },
    components: {
      AppButton
    },
    computed: {
    
    },
    //props:["instruction","title","button_next"],
    methods: {
      handlePrev() {
        this.$router.push('setup-locations');
      },
      handleSubmit() {
        this.$emit("next");
      },
    },
    data() {
      return {
      }
    },
    destroyed() {
        // window.cancelAnimationFrame(this.id);
    },
    mounted() {
      // 

        if (this.$store.getters["game/gamestarted"]) {
          this.$store.dispatch("setHeaderContent", {caption: "Hervat spel" } );
        }
        else {
          this.$store.dispatch("setHeaderContent", {caption: "Start spel" } );
        }

    }
  }
</script>

<style scoped>

.panel {
    position: absolute;
    width: 100vw;
    height: 90vh;
    background-color: var(--green-middle-color);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:6;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}


.icon {
  width:140px;
  margin-bottom: 30px;
}
.title {
  /* margin-bottom:8px; */
  font: calc(40px / var(--scale) )/ calc(35px / var(--scale) ) Fira Sans Bold;
  color:var(--light-text-color);
  text-transform: uppercase;

  /* background-color: blue; */

}

.instruction {
  font: calc(25px / var(--scale) )/ calc(35px / var(--scale) ) Fira Sans Bold;
  color:var(--dark-text-color);
  text-align: center;
  margin-top:10px;

  /* background-color: red; */
}


.button {
  margin-top: 20px;
}

.button-prev {
  margin-top: 30px;
  margin-left:9px;
}

</style>