<template>
    <div class="button" :class="selected? 'selected':''" @click="handleClick">{{caption}}</div>
</template>

<script>
  export default {
    name: 'game-button',
    props: ['caption','selected'],
    mounted() {

    },
    data() {
        return {
          // selected: false
        }
    },
    computed: {        

    },
    methods: {
      handleClick() {
        this.$emit("click");
      },
      // setSelected(bool) {
      //   this.selected = bool;
      // }
    }
  }
</script>

<style scoped>
  
  .button {
    width: calc(179px / var(--scale) );
    height: calc(67px / var(--scale) );
    background-color: white;
    color: black;
    font: calc(35px / var(--scale) )/calc(67px / var(--scale) ) Fira Sans Bold;
    text-align: center;
  }

  .selected {
    border: 3px solid var(--purple-dark-color);
    line-height:  calc(58px / var(--scale) );
    color: var(--purple-dark-color);
  }
</style>