<template>
    <div ref="outer" id="outer" class="outer">
        <img ref="inner" id="inner" class="inner" :src="star" />
    </div>
</template>

<script>


export default {
    name: "AnimatedStar",
    props: ['index'],
    components: {
    },    
    data() {
        return {

        };
    },
    methods: {
    },
    mounted() {
        const t1 = Math.random() ;
        this.$refs.inner.style.setProperty('--animation-delay', t1 +'s');

        const t2 = (Math.random() * 3);
        const s = 40 + Math.floor(Math.random() * 30);

        const l = Math.floor(Math.random() * 70);
        const t = ((this.index-1) * 25) + Math.floor(Math.random() * 10);
        this.$refs.outer.style.setProperty('--animation-delay', t2 +'s');
        this.$refs.outer.style.setProperty('--size', s +'px');
        this.$refs.outer.style.setProperty('--top', t +'%');
        this.$refs.outer.style.setProperty('--left', l +'%');

    },
    computed: {
        star () {
            let stars = ['white','yellow'];
            let index = Math.floor( Math.random() * 2);
            return "./static/images/star-" + stars[index] + ".svg";
        }
    },
};
</script>


<style scoped>

.outer {
    position: absolute;
    animation: spin 3s infinite;
    width: var(--size) ;
    height: var(--size) ;    
    padding: 0;
    margin: 0;
    animation-delay: var(--animation-delay);
    left:var(--left);
    top:var(--top);
}

.inner {
    animation: crescendo 1s alternate infinite;
    width:100%;
    height:100%;
    animation-delay: var(--animation-delay);
    /* border:1px solid red; */

}

#star {
    fill: white;
}

@keyframes crescendo {
    0%{
      transform: scale(1) ;
    }
    100% {
      transform: scale(1.5) ;
    }
}
@keyframes spin {
    0%{
      transform:  rotate(0deg);
    }
    /* 40% {
      transform: rotate(360deg);
    } */
    100% {
      transform: rotate(360deg);
    }
}


</style>