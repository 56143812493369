<template>
  <div class="panel">
        <div class="left">
            <div class="photo">
                <img :src="`./static/content/questions/images/${this.$store.getters['game/currentLocation'].image1.image}`" />
                <div class="attribution">
                    {{ this.$store.getters['game/currentLocation'].image1.attribution }}
                </div>
            </div>
        </div>
        <div class="center">
            <div class="intro">
            {{this.$store.getters['game/currentQuestion'].intro}}
            </div>
        </div>
        <div class="right">
            <app-button color="purple" bevel="true" :caption="$t('button-next')"  @click="handleSubmit" />
            <!-- <app-button class="prevbutton" color="purple" bevel="false" :caption="$t('button-prev')"  @click="handlePrev" /> -->
        </div>
  </div>
</template>

<script>

import AppButton from  "@/components/ui/AppButton.vue";

export default {
    name: "QuestionPanelIntro",
    components: {
      AppButton,
    },    
    props: {
    },
    data() {
        return {

        };
    },
    methods: {
        handleSubmit() {
            this.$emit("next")
        },
        handlePrev() {
            this.$emit("prev")
        }        
    },
    mounted() {
        // console.log( this.$store.getters['game/currentLocation'] )
         console.log( this.$store.getters['game/currentQuestion'] )
    
    },
    computed: {
       
    },
};
</script>


<style scoped>

.panel {
    position: absolute;
    background-color: var(--green-middle-color);
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
}

.left {
    width:38%;
    display: flex;
    align-items: center;
}

.photo {
    position: relative;
    width: calc(470px / var(--scale) );
    height: calc(551px / var(--scale) );    
    /* margin-top: calc(40px / var(--scale) ); */
    /* border:1px solid red; */
}

.photo img {
    position: absolute;
    width: 100%;
    height: 100%;    
}

.photo .attribution {
    position: absolute;
    margin:2px;
    bottom:0;
    right:0;
    font: calc(16px / var(--scale) )/calc(20px / var(--scale) ) Fira Sans Light;
    /* text-shadow: 2px 2px #ffffff; */
    color:white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    letter-spacing: 1px;
}


.center {
    width:40%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.intro {
    width: calc(408px / var(--scale) );
    /* height: calc(191px / var(--scale) );     */
    letter-spacing: var(--unnamed-character-spacing-0);
    font: calc(26px / var(--scale) )/calc(40px / var(--scale) ) Fira Sans Regular;
}

.right {
    width:15%;
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    justify-content: center;
    /* padding-top: calc(240px / var(--scale) );  */
    /* border:1px solid red; */
    /* justify-content: center; */
    /* background-color: red; */
}


.prevbutton {
    margin-top:40px;
    margin-right:-5px;
}

</style>