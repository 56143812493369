import L from "leaflet";
const EventEmitter = require('events');

export class QuestionMarker extends EventEmitter {
    constructor(questionId, pos){
        super();

        this.questionId = questionId;
        this.selected = false;
        this.result = '';
        this.isEndGameMarker = false;
        this.isInteractive = false;
        // distance property to sort the markers by distance, for random first nearest question
        this.dist = 0;
    
        this.marker = L.marker(pos, {id:questionId, selected:false}); //L.marker(pos, {title: questionId});
    
        this.marker.on('click', ()=>{
            // emit event
            this.emit("click",this);

            //this.setSelected(!this.selected);
        });

        this.marker.on('dblclick', ()=>{
            // emit event
            this.emit("fakehit",this);

            //this.setSelected(!this.selected);
        });
    }

    setDistance(d) {
        this.dist = d;
    }
    setFeedbackIcons(correctIcon, incorrectIcon) {
        this.correctIcon = correctIcon;
        this.incorrectIcon = incorrectIcon;
    }
    setLabel(caption, type){
        let icon;
            
        switch(type) {
            case "default":
                this.isInteractive = false;
                icon = L.divIcon({
                    className: 'icon-question-default',
                    iconSize: [23,23],
                    html: '<div id="label" class="question-caption">' + caption + '</div>'
                });
                break;
            case "interactive" :
                this.isInteractive = true;
                icon = L.divIcon({
                    className: 'icon-endgame-interactive',
                    iconSize: [200,68],
                    html: '<div class="endgame-label"><div id="circle" class="endgame-circle"></div><div id="caption" class="endgame-caption">' + caption + '</div></div>'
                });
                break;
        }
        this.marker.setIcon(icon);
    }

    setSelected(bool) {
        this.selected = bool;
        this.marker.options.selected = bool;
        if (this.marker.getElement()) {
            if (this.isEndGameMarker && this.isInteractive) {
                if (bool) {
                    this.marker.getElement().getElementsByClassName("endgame-caption")[0].classList.add("selected");                 
                    this.marker.getElement().getElementsByClassName("endgame-circle")[0].classList.add("selected");                     
                }
                else {
                    this.marker.getElement().getElementsByClassName("endgame-caption")[0].classList.remove("selected");                 
                    this.marker.getElement().getElementsByClassName("endgame-circle")[0].classList.remove("selected");                     
                }
            }
            else {
                if (this.result === '' || this.isEndGameMarker) {
                    // only when question is not yet answered
                    if (bool) {
                        this.marker.getElement().classList.add("selected");
                        this.marker.getElement().getElementsByClassName("question-caption")[0].classList.add("selected");                 
                    }
                    else {
                        this.marker.getElement().classList.remove("selected");
                        this.marker.getElement().getElementsByClassName("question-caption")[0].classList.remove("selected");    
                    }        
                }
            }
        }        
    }

    setResult(result) {
        if (result != this.result) {
            this.result = result;
            if (result === 'correct'){
                let icon = L.divIcon({
                    className: 'icon-feedback-question',
                    iconSize: [34,34],
                    html: '<img src="./static/images/diamonds/' + this.correctIcon + '" />'
                });
    
                this.marker.setIcon(icon);    
            }
            else if (result === 'incorrect'){
                let icon = L.divIcon({
                    className: 'icon-feedback-question',
                    iconSize: [34,34],
                    html: '<img src="./static/images/diamonds/' + this.incorrectIcon + '" />'
                });
                this.marker.setIcon(icon);    
            }
        }
    }

    isCompleted() {
        return (this.result != '');
    }

    hide () {
        this.marker.hide();
    }
}