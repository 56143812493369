<template>
  <div class="panel">
    <div class="top">
        <div class="left">
            <div class="prize">
                <div class="caption">{{$t('caption-prize')}}</div>
                <div class="diamond">
                    <div class="circle"></div>
                    <img :src="`./static/images/diamonds/${this.$store.getters['game/currentLocation'].prize}`" />
                </div>
            </div>
            <div class="photo">
                <img :src="`./static/content/questions/images/${this.$store.getters['game/currentLocation'].image2.image}`" />
                <div class="attribution">
                    {{ this.$store.getters['game/currentLocation'].image2.attribution }}
                </div>
            </div>
        </div>
        <div class="center">
            <div class="center-up">
                <div v-html="this.$store.getters['game/currentQuestion'].text" class="text">
                    <!-- {{ this.$store.getters['game/currentQuestion'].text }} -->
                </div>
            </div>
            <div class="center-down">
                <drag-drop-question v-if="this.$store.getters['game/currentQuestion'].type === 'dragdrop'" ref="question" @change="handleAnswerChange" @result="handleAnswerResult" />
                <multiple-choice-question v-else ref="question" @change="handleAnswerChange" @result="handleAnswerResult" />
            </div>
        </div>
        <div class="right">
            <app-button v-if="state=='answer'" ref="submit" color="purple" bevel="true" :caption="$t('button-check')"  @click="handleCheck" />
            <app-button v-if="state=='answer'" class="prevbutton" color="purple" bevel="false" :caption="$t('button-prev')"  @click="handlePrev" />
            <app-button v-if="state=='feedback'" color="purple" bevel="true" :caption="$t('button-next')"  @click="handleSubmit" />
        </div>
    </div>
    <div class="bottom">
        <div :v-if="feedback_text" :class="['feedback',feedback_status != ''? feedback_status + '-color' : '']">
            {{ feedback_text }}
        </div>
    </div>   
  </div>
</template>

<script>

import AppButton from  "@/components/ui/AppButton.vue";
import MultipleChoiceQuestion from '@/components/student/QuestionPanel/MultipleChoiceQuestion.vue';
import DragDropQuestion from '@/components/student/QuestionPanel/DragDropQuestion.vue';

export default {
    name: "QuestionPanelQuestion",
    components: {
      AppButton,
      MultipleChoiceQuestion,
      DragDropQuestion
    },    
    props: {
    },
    data() {
        return {
            feedback_text:'',
            feedback_status: '',
            state: 'answer'
        };
    },
    methods: {
        handleAnswerChange(bool) {            
            this.$refs.submit.enabled(bool);
        },        
        handleAnswerResult(result) {
            let snd;
            if (result === "correct") {
                // yes, all correct
                //console.log("CORRECT")
                if (this.$store.getters['game/currentQuestion'].feedback_correct != ''){
                    this.feedback_text = this.$store.getters['game/currentQuestion'].feedback_correct;
                    this.feedback_status = 'correct';
                }
                snd = new Audio("./static/endgame/sounds/secretzelda.mp3");
            }
            else {
                // nope, something is missing
                //console.log("ERROR")
                if (this.$store.getters['game/currentQuestion'].feedback_incorrect != ''){

                    this.feedback_text = this.$store.getters['game/currentQuestion'].feedback_incorrect;
                    this.feedback_status = 'incorrect';
                }
                snd = new Audio("./static/endgame/sounds/wrong.mp3");
            }   
            
            snd.play();
            // master check is for testing purposes, go immediatly to feedback 
            // when logged in as master to make it easier to test without being a group and needing a classroom
            if (!this.$store.getters['user/isMaster']){
                // save results
                let answerresult = {
                    id: this.$store.getters['game/currentLocation'].id,
                    result: result,
                }

                // total score will be calculated in setAnswer

                this.$store.dispatch("game/setAnswer",answerresult)
                .then(() => {
                    this.state = 'feedback';
                //     this.$store.dispatch("game/getLeaderBoard")
                //     .then(() => {
                //         let msg = {
                //             groupname: this.$store.getters["user/groupname"],
                //             action: "scoreUpdate",
                //             score: this.$store.getters["game/score"]
                //         }
                //         this.$store.dispatch("socket/sendToAll",msg);
                //         this.state = 'feedback';       
                //     })
                //     .catch(error => {
                //         console.log(error);
                //     });
                })
                .catch(error => {
                        console.log(error);
                });
            }
            else {
                this.state = 'feedback';       
            }


        },
        handleCheck() {
            this.$refs.question.checkResult();     
        },
        handleSubmit() {
            this.$emit("next")
        },
        handlePrev() {
            this.$emit("prev")
        }        
    },
    mounted() {
        if (this.$store.getters['game/currentQuestion'].type != 'dragdrop') {
            this.$refs.submit.enabled(false);
        }
        this.currentAnswers = {};
    },
    computed: {},
};
</script>


<style scoped>

.panel {
    position: absolute;
    background-color: var(--green-middle-color);
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
}

.top {
    width: 100%;
    /* height:80%; */
    display:flex;
}



.left {
    width:32%;
    display: flex;
    flex-direction: column;
    /* border:1px solid red; */
    /* justify-items: center; */
}

.photo {
    position: relative;
    width: calc(402px / var(--scale) );
    height: calc(346px / var(--scale) ); 
    margin-top: calc(37px / var(--scale) );
    /* border:1px solid red; */
}

.photo img {
    position: absolute;
    width: 100%;
    height: 100%;    
}

.photo .attribution {
    position: absolute;
    margin:2px;
    bottom:0;
    /* bottom:-18px; */
    right:0;
    font: calc(14px / var(--scale) )/calc(20px / var(--scale) ) Fira Sans Light;
    /* text-shadow: 2px 2px #ffffff; */
    color:white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; 
    letter-spacing: 1px;

    /* color:black;
    letter-spacing: 1px;
    /* mix-blend-mode: exclusion; */
    /* opacity: .7; */
}

.prize {
    background-color: var(--green-dark-color);
    margin-top: calc(75px / var(--scale) );
    width: calc(293px / var(--scale) );
    height: calc(138px / var(--scale) );    

    display: flex;
}

.caption {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font: calc(30px / var(--scale) )/calc(21px / var(--scale) ) Fira Sans Medium;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    width:60%;
}

.diamond {
    /* background-color: brown; */
    position: relative;
    display: flex;
    width:50%;
    justify-content: center;
    align-items: center;
}

.diamond img {
    position: absolute;
    /* width: calc(57px / var(--scale) ); */
    height: calc(57px / var(--scale) );   
    /* display: none;  */
}

.diamond .circle {
    position: absolute;
    height: calc(96px / var(--scale) ); 
    width: calc(96px / var(--scale) ); 
    background-color: white;
    opacity: 0.45;
    border-radius: 50%;
}

.center {
    width:46%;
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    padding-left: calc(30px / var(--scale) ); 
    padding-right: calc(20px / var(--scale) ); 

}

.center-up {
    height: calc(244px / var(--scale) ); 
    /* border:1px solid blue; */
    display: flex;
    /* flex-direction: column; */
    /* justify-content: center; */
    align-items: center; 
}

.text {
    /* width: calc(550px / var(--scale) ); */
    font: calc(26px / var(--scale) )/calc(40px / var(--scale) ) Fira Sans Regular;
    /* border:1px solid red; */
}

.center-down {
    /* border:1px solid green; */
     height: calc(246px / var(--scale) ); 
    /* border:1px solid blue; */
}

.right {
    width:15%;
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    padding-top: calc(240px / var(--scale) ); 
    /* border:1px solid red; */
    /* justify-content: center; */
}

.prevbutton {
    margin-top:40px;
    margin-right:-5px;
}

.bottom {
    height: calc(125px / var(--scale) );
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    /* align-items: center; */
    /* height:20%; */
    /* background-color: orange; */
}

.feedback {
    margin-top: calc(18px / var(--scale) ); 
    padding:  calc(5px / var(--scale) ); 
    width: calc(1200px / var(--scale) ); 
    /* height: calc(85px / var(--scale) );  */
    text-align: center;
}

.correct-color {
    background-color: white;
    color:#58A333;
    color:black;
}

.incorrect-color {
    background-color: white;
    color:#D60D41;
}

</style>