<template>
    <ul class="answers">
        <multiple-choice-answer 
            v-for="(answer,index) in this.$store.getters['game/currentQuestion'].answers"  
            :key="index" 
            :text="answer.text"
            :id="answer.id"  
            ref="answers"

            @change="handleAnswerChange"
            />
    </ul>
</template>

<script>

import MultipleChoiceAnswer from '@/components/student/QuestionPanel/MultipleChoiceAnswer.vue';

export default {
    name: "MultipleChoiceQuestion",
    components: {
      MultipleChoiceAnswer
    },    
    props: {
    },
    data() {
        return {
        };
    },
    methods: {
        handleAnswerChange(selectedAnswer) {
            let maxAnswers = Number(this.$store.getters['game/currentQuestion'].max_answers);
            let numAnswers = 0;

            if (maxAnswers > 0) {
                if (maxAnswers === 1) {
                    if (selectedAnswer.isSelected) {
                        // one answer possible, toggle other answers of first
                        this.$refs.answers.forEach( (answer) => {
                            if (answer.isSelected){
                                numAnswers++;
                            }
                            answer.reset();
                        });
                        selectedAnswer.setSelected(true);
                    }
                }
                else {
                    // more answers possible, prevent user from selecting too many answers
                    this.$refs.answers.forEach( (answer) => {
                        if (answer.isSelected){
                            numAnswers++;
                        }
                    });
                    //console.log(numAnswers)
                    // if (numAnswers > maxAnswers) {
                    //    selectedAnswer.setSelected(false);
                    // }
                }
            }
            else {
                // no hint about the number of answers
                //selectedAnswer.setSelected(true);
                this.$refs.answers.forEach( (answer) => {
                    if (answer.isSelected){
                        numAnswers++;
                    }
                });
            }
            
            // emit change 
            //this.$refs.submit.enabled((numAnswers > 0));
            let enableCheckButton = false;
            if (numAnswers > 0) {
                if (maxAnswers > 1) {
                    enableCheckButton = (numAnswers === maxAnswers);
                }
                else {
                    enableCheckButton = true;
                }
            }
            
            this.$emit("change", enableCheckButton);

        },        
        checkResult() {
            // check results 
            let numCorrectAnswers = 0;
            this.$refs.answers.forEach( (answer) => {
                    if (answer.isSelected){
                        if (this.correctAnswers.indexOf(answer.id) >= 0) {
                            numCorrectAnswers++;
                            answer.setFeedback('correct')
                        }
                        else {
                            answer.setFeedback('incorrect')
                        }
                    }
                    answer.setEnabled(false);
                    // disable answers

                    // else {
                    //     answer.reset();
                    // }
            });
            if (this.correctAnswers.length === numCorrectAnswers) {
                // yes, all correct
                // emit correct
                this.$emit("result","correct");
            }
            else {
                // nope, something is missing
                // emit incorrect
                this.$emit("result","incorrect");
            }     
            
        }   
    },
    mounted() {
        this.correctAnswers = this.$store.getters['game/currentQuestion'].correct.split("-");
    },
    computed: {
        
    },
};
</script>


<style scoped>



.answers {
    list-style: none;
    margin:0;
    padding:0;
    /* margin-bottom: calc(20px / var(--scale) );   */
}



</style>