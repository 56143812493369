
import L from "leaflet";

export class UserMarker {
    constructor( pos){

        this.pos = pos;
        this.offline = false;

        let icon = L.divIcon({
            className: 'user-shield',
            iconSize: [35,37],
        });

        this.marker = L.marker(pos, {icon:icon});
    }

    setMaster() {
        let icon = L.divIcon({
            className: 'user-black-square',
            iconSize: [40,40],
           html: '<div id="label" class="master-label"></div>'
        });
    
        this.marker = L.marker(this.pos, {icon:icon});
    
        // testing
        // this.marker.on('click', ()=>{
        //     this.setOutOfBounds(!this.outofbounds);
        // });
    }

    setUserId(id) {
        this.outofbounds = false;
        this.id = id;
        let icon = L.divIcon({
            className: 'user-black-square',
            iconSize: [23,23],
            html: '<div id="label" class="user-label">' + this.id + '</div>'
        });
    
        this.marker = L.marker(this.pos, {icon:icon});
    
        // testing
        // this.marker.on('click', ()=>{
        //     this.setOffline(!this.offline);
        // });
        
    }
    
    setOutOfBounds(bool) {
        this.outofbounds = bool;
        if (this.marker.getElement()) {
            if (bool) {
                this.marker.getElement().classList.add("user-out-of-bounds");
                this.marker.getElement().getElementsByClassName("user-label")[0].classList.add("label-out-of-bounds");    
            }
            else {
                this.marker.getElement().classList.remove("user-out-of-bounds");
                this.marker.getElement().getElementsByClassName("user-label")[0].classList.remove("label-out-of-bounds");    
            }    
        }    
    }
    
    setOffline(bool) {
        this.offline = bool;
        if (this.marker.getElement()) {
            if (bool) {
                this.marker.getElement().classList.add("user-offline");
            }
            else {
                this.marker.getElement().classList.remove("user-offline");
            }    
        }    
    }
}

// UserMarker.prototype.setGroup = function(groupname) {
//     let icon = L.divIcon({
//         className: 'user-black-square',
//         iconSize: [23,23],
//         html: '<div id="label" class="user-label">' + groupname + '</div>'
//     });

//     this.marker = L.marker(this.pos, {icon:icon});

// }

