<template>
  <div class="page">
    <div class="map-panel">
        <div class="map" id="map"></div>        
    </div>
    <!-- <div v-if="this.$route.name != 'game'" class="button-panel"> -->
    <!-- <div v-if="(this.$route.params.option === 'setup-locations' && this.$store.getters['game/numberOfRounds'] > 1) " class="left">
        <game-button class="game-button" :caption="$t('button-round-1')" :selected="this.$store.getters['game/currentRound'] == 0" @click="selectRound(0)" />
        <game-button class="game-button" :caption="$t('button-round-2')" :selected="this.$store.getters['game/currentRound'] == 1" @click="selectRound(1)"/>
    </div> -->
    <div v-if="this.$route.params.option === 'game'" class="game-controls">
        <!-- <game-button caption="dim" @click="dimScreen" selected="false" /> -->

        <div class="left">
            <img class="button-leaderboard game-button" :src="this.$store.getters['game/dimmed']?`./static/images/bt-undim.png`: `./static/images/bt-dim.png`"  @click="handleDim"  />
            <game-button class="game-button" :caption="$t('button-endgame')" @click="startEndGame" selected="true" />
        </div>

        <img class="button-leaderboard game-button" src="~@/assets/bt-leaderboard.png" @click=" $refs.leaderBoard.show() "  />
        <!-- <img class="button-dim" :src="dimscreen ? '~@/assets/bt-undim.png': '~@/assets/bt-dim.png'" @click="handleDim"  /> -->
        <!-- <div class="game-code">code:{{this.$store.getters["game/code"]}}</div> -->
    </div>
    <div v-if="(this.$route.params.option != 'game')" class="right">
        <app-button ref="nextbutton" color="purple" bevel="true" :caption="$t('button-next')"  @click="handleNext" />
        <app-button class="prevbutton" color="purple" bevel="false" :caption="$t('button-prev')"  @click="handlePrev" />
    </div>
    <game-starter v-if="(this.$route.params.option === 'start-game')" @next="handleNext" />
    <!-- </div> -->

    <leader-board ref="leaderBoard"/>    

    <popup-panel ref="popup" class="popup-panel" @close="handlePopupClose" />
    <confirm-panel ref="confirm" class="confirm-panel" @ok="handleConfirmOk" @cancel="handleConfirmCancel" />

  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import "@/assets/css/QuestionMarker.css";
import {QuestionMarker} from "@/classes/QuestionMarker"

import "@/assets/css/UserMarker.css";
import {UserMarker} from "@/classes/UserMarker"


import AppButton from  "@/components/ui/AppButton.vue";
import GameButton from  "@/components/begeleider/GameButton.vue";

import GameStarter from  "@/components/begeleider/GameStarter.vue";
import PopupPanel from "@/components/ui/PopupPanel.vue";
import ConfirmPanel from "@/components/ui/ConfirmPanel.vue";
import LeaderBoard from  "@/components/begeleider/LeaderBoard.vue";

import SocketClientHelper from "@/helpers/SocketClientHelper"

export default {
    name: "MapScreenBegeleider",
    map: null,
    bounds: null,
    user: null,
    debuglabel: null,
    debugid: '',
    debuglatlng: null,
    locationsLayer: null,
    timer: null,
    components: {
        AppButton,
        GameButton,
        GameStarter,
        PopupPanel,      
        ConfirmPanel,
        LeaderBoard
    },
    data() {
        return {
            // questions: [],
            MODE: 'debug',
            questionmarkers: [],
            //endgamemarkers:[],
            groupmarkers:{},
            endgamemarkers: [],
            hitDistance: 15,
            zoom: 17,
            center: L.latLng(52.08275898178785, 4.312721966266951), // Lange Voorhout
            offsetlat:0,
            offsetlng:0,
            //gamebounds: null,
            leafletOptions : {
                zoomSnap: 0.1,
                zoomControl: false,
                maxBoundsViscosity: 1.0,
                gestureHandling:false,
                attributionControl:false 
            },
            gpsOptions: {
                enableHighAccuracy: true,
                timeout: 2999,
                maximumAge: 0,
            },
            watchTracker: null,
            dimmed: false
        };
    },
    methods: {
        handleConfirmOk(id) {
            this.$refs.confirm.hide();

            if (id === "begeleider-confirm-start-endgame") {
                // ok on the endgame
                // clear the  timer
                this.$store.dispatch('clock/clearTimer');                

                // send endgame to users
                let msg = {
                    action: "endgame",
                    master_action: "startEndgame",
                    // dimmed: false,
                    // round: "endgame"
                }
                let opts = SocketClientHelper.createMessageToStudents(msg)
                this.$socket.client.emit('event',opts); 
    
                this.$store.dispatch("game/setGameStatus", "endgame" );

                // start the endgame timer
                this.$store.dispatch('clock/startTimer', this.$store.getters['gamesettings/endgameduration'] * 60);

                let caption = 'Begeleider <span style="color:black">' 
                        + this.$store.getters['user/roomname']  
                        + '  &nbsp;~ EINDSPEL ~&nbsp;</span> code: VOGELS';
                this.$store.dispatch("setHeaderContent", {caption: caption} );

                this.$router.push('/begeleider/endgame/instructions');
            }
            else if (id === "begeleider-confirm-dim-screen") {
                // send endgame to users
                let msg = {
                    action: this.$store.getters["game/status"],
                    master_action: "dimScreens",
                    dimmed: true,
                    // round: "endgame"
                }
                let opts = SocketClientHelper.createMessageToStudents(msg)

               // console.log(opts)
                this.$socket.client.emit('event',opts); 

                this.$store.dispatch("game/dimScreen", true);
                this.$store.dispatch("clock/pauseTimer",true);

            }
            else if (id === "begeleider-confirm-undim-screen") {
                // send endgame to users
                let msg = {
                    action: this.$store.getters["game/status"],
                    master_action: "dimScreens",
                    dimmed: false,
                    // round: "endgame"
                }
                let opts = SocketClientHelper.createMessageToStudents(msg)
                this.$socket.client.emit('event',opts); 

                this.$store.dispatch("game/dimScreen", false);
                this.$store.dispatch("clock/pauseTimer",false);
            }
        },
        handleConfirmCancel() {
            this.$refs.confirm.hide();
        },
        handleDim() {
            if (this.$store.getters['game/dimmed']) {
                this.$refs.confirm.show("begeleider-confirm-undim-screen");
            }
            else {
                this.$refs.confirm.show("begeleider-confirm-dim-screen");
            }
        },
        dimScreen() {
            this.$store["screen/dimScreens",true];
        },
        startEndGame() {
            this.$refs.confirm.show("begeleider-confirm-start-endgame");

        },
        handlePopupClose() {
            this.$refs.popup.hide();
        },
        handleTimeout() {
            // close question

            let newround = this.$store.getters['game/currentRound'] + 1;
            if (newround <  this.$store.getters['gamesettings/numberOfRounds']) {
                this.$refs.popup.show("begeleider-round-timeout");

                this.$store.dispatch('game/setCurrentRound', newround);
                this.startRound();
            }
            else {
                this.$refs.popup.show("begeleider-endgame-locations-timeout");

                this.$store.dispatch('game/setCurrentRound', 99);

                let caption = 'Plattegrond <span style="color:black">locatie eindspel</span>';
                this.$store.dispatch("setHeaderContent", {caption: caption} );

                // focus on the endgame icon
                this.drawLocations()
            }
        },
        handleRouteNavigation() {
            clearInterval(this.timer);

            if (this.$route.params.option === 'setup-endgame'){
                // let begeleider select an endgame location
                let caption = '<span style="color:black">Kies een locatie voor het eindspel</span>';

                this.$store.dispatch("setHeaderContent", {caption: caption} );

                this.drawSelectableEndgameMarkers();

                this.$refs.nextbutton.enabled(this.$store.getters["gamesettings/selectedEndgameLocationId"] != null);

            }
            else if (this.$route.params.option === 'setup-locations'){
                // let begeleider select an endgame location
                let caption = '<span style="color:black">Vragen selecteren</span>';

                this.$store.dispatch("setHeaderContent", {caption: caption} );

                this.drawSelectableMarkers();

                this.$refs.nextbutton.enabled(true);
            } 
            else if (this.$route.params.option === 'start-game') {
                let caption = 'Start het spel';
                this.$store.dispatch("gamesettings/encodeGameInfo");
                this.$store.dispatch("setHeaderContent", {caption: caption} );
            }
            else if (this.$route.params.option === 'game'){
                
                console.log("GAME")

                this.groupmarkers = {};

                clearInterval(this.timer);
            
                this.timer = setInterval(this.updateGroupLocations,1000);

                if (this.$store.getters['game/currentRound'] > this.$store.getters['gamesettings/numberOfRounds']) {
                    this.drawLocations();
                }
                else {
                    this.startRound();
                }
            }                
        },        
        handleNext() {
             //console.log(this.$route.params.option)
            if (this.$route.params.option === 'setup-locations') {
                let selected_locations = [];
                this.locationsLayer.eachLayer(loc => {
                    if (loc.options.selected) {
                        selected_locations.push(loc.options.id);
                    }
                });

                // save selected locations
                this.$store.dispatch("gamesettings/updateSelectedLocations",selected_locations);

//                this.$store.dispatch("game/encodeGameSettings")

                this.$router.push('start-game');
            }
            else if (this.$route.params.option === 'setup-endgame') {                
                this.$router.push('setup-locations');
            }
            else if (this.$route.params.option === 'start-game') {

                // send game start to sockets
                // let msg = {
                //     action: "startGame",
                //     //code : this.$store.getters["gamesettings/code"],
                //     // dimmed: false,
                //     round: "0"
                // }
                // console.log("code:" + msg.code)
                // this.$store.dispatch("socket/sendToStudents",msg,{root:true});
                if ( !this.$store.getters["game/gamestarted"]) {
                    // send endgame to users
                    let msg = {
                        // this action gets saved
                        action: "started",
                        // this action gets read
                        master_action: "startGame",
                        code: this.$store.getters["gamesettings/code"],
                        // dimmed: false,
                        // round: "endgame"
                    }
                    let opts = SocketClientHelper.createMessageToStudents(msg)
                    this.$socket.client.emit('event',opts); 
                    this.$store.dispatch("game/setGameStatus", "started" );
                    this.$store.dispatch('game/setCurrentRound', 0);
                }

                this.$router.push('game');
            }
        },
        handlePrev() {
            if (this.$route.params.option === 'setup-endgame') {
                //this.$router.push('setup-locations');
                this.$router.push('/setup/begeleider/rounds');
            }
            else if (this.$route.params.option === 'setup-locations') {
                //this.$router.push('/setup/begeleider/rounds');
                this.$router.push('setup-endgame');
            }
        },
        // selectRound(round) {
        //     this.$store.dispatch('game/setCurrentRound', round);
        //     this.drawSelectableMarkers();
        // },
        drawSelectableMarkers() {
            let locations = this.$store.getters['gamesettings/locations'];

            //this.questionmarkers = [];
            this.locationsLayer.clearLayers();
            locations.forEach( (location) => {

                if (location) {
                    // let pos = location.latlng.split(",");
                    // let qlatlng = this.convertLocationForTesting(new L.LatLng(pos));   
                    //let qlatlng = this.convertLocationForTesting(new L.LatLng(location.latlng));                    
                    let pos = location.latlng.split(",");
                    let qlatlng = this.convertLocationForTesting(
                        new L.LatLng(
                            Number(pos[0]),
                            Number(pos[1]) 
                        )
                    );                    

                    let qm = new QuestionMarker(location.id, qlatlng);
                    qm.setLabel(location.title, 'default');

                    // save the markers for calculating the distance
                    //this.questionmarkers.push(qm);
                    qm.marker.addTo(this.locationsLayer);

                    if (this.$store.getters['gamesettings/selected_locations'].indexOf( qm.questionId ) >= 0) {
                        qm.setSelected( true );
                    }

                    qm.on('click', (qm) => {
                        if (qm.selected) {
                            qm.setSelected( false );
                        }
                        else {
                            qm.setSelected( true );
                        }
                    });
                }
            }); 
        },
        drawLocations() {

            this.locationsLayer.clearLayers();

            let locations = this.$store.getters['gamesettings/selected_locations'];
            
            //console.log(locations)
            // draw all selected question locations
            locations.forEach( (id) => {

                let location = this.$store.getters['gamesettings/locations'].find(location => location.id === id.toString());

                if (location) {
                  
                    let pos = location.latlng.split(",");
                    let qlatlng = this.convertLocationForTesting(
                        new L.LatLng(
                            Number(pos[0]),
                            Number(pos[1]) 
                        )
                    );                    

                    let qm = new QuestionMarker(location.id, qlatlng);
                    qm.setLabel(location.title, 'default');

                   // console.log(qm)

                    // add to the map
                    qm.marker.addTo(this.locationsLayer);

                    // all rounds completed, endgame focus
                    if (this.$store.getters['game/currentRound'] > this.$store.getters['gamesettings/numberOfRounds']) {
                        qm.marker.setOpacity(.3);
                    }
                }
            });      
            
            // draw endgame location
            let endgamelocation = this.$store.getters['gamesettings/selectedEndGameLocation'];
            
            let pos = endgamelocation.latlng.split(",");
            let qlatlng = this.convertLocationForTesting(
                new L.LatLng(
                    Number(pos[0]),
                    Number(pos[1]) 
                )
            );                    

            let qm = new QuestionMarker(endgamelocation.id, qlatlng);
            qm.setLabel(endgamelocation.title, 'default');
            qm.isEndGameMarker = true;

            qm.marker.addTo(this.locationsLayer);
        },
        drawSelectableEndgameMarkers() {

            this.endgamemarkers = [];
            this.locationsLayer.clearLayers();

            let endgamelocations = this.$store.getters['gamesettings/endgameLocations'];

            endgamelocations.forEach( (location) => {
                if (location) {

                    let pos = location.latlng.split(",");
                    let qlatlng = this.convertLocationForTesting(
                        new L.LatLng(
                            Number(pos[0]),
                            Number(pos[1]) 
                        )
                    );                    

                    let qm = new QuestionMarker(location.id, qlatlng);

                    qm.setLabel(location.title, 'interactive');
                    qm.isEndGameMarker = true;

                    // we need this for the location check loop
                    this.endgamemarkers.push(qm);

                    qm.on('click', (elm) => {
                        this.selectEndGame(elm);
                    });

                    qm.marker.addTo(this.locationsLayer);

                    if (qm.questionId === this.$store.getters["gamesettings/selectedEndgameLocationId"]) {
                        qm.setSelected(true);
                    }
                }
            });            
        },   
        updateGroupLocations() {

            // TODO: fix situation with stale group icons 

            //console.log("update group")
            let groups = this.$store.getters["groups/groups"];
            let socketids = Object.keys(groups);

            socketids.forEach( (id) => {                     

                //console.log(id)
                let group = groups[id];

                if (group && group.location) {

                    let gm;
                    
                    if (this.groupmarkers[id]) {
                        // we already have this user, update location
                        gm = this.groupmarkers[id];
                        gm.marker.setLatLng(group.location); 
                    }
                    else {
                        // create a new marker
                        gm = new UserMarker(group.location);
                        gm.setUserId(group.index);
                        // gm.setGroupName(group.groupname);
                        //gm.setOutOfBounds(group.outofbounds);
                        this.groupmarkers[ id ] = gm;

                        gm.marker.addTo(this.map);
                    }

                    gm.setOffline( !group.online );
                }
            });
        },
        selectEndGame(elm) {

            this.endgamemarkers.forEach( (marker) => {                     
                marker.setSelected(false);
            });
            elm.setSelected(true);

            this.$store.dispatch("gamesettings/setEndGameLocation",elm.questionId);

            this.$refs.nextbutton.enabled(true);
        },       
        setupLeafletMap: function () {

            // for easier handling of all the markers
            this.locationsLayer =  L.featureGroup();
            
            this.map = L.map("map",this.leafletOptions).setView(this.center, this.zoom);

            this.map.addLayer(this.locationsLayer);
            //Actueel_ortho25
            //brtachtergrondkaartgrijs
             if (process.env.VUE_APP_FIXED_LANGE_VOORHOUT === "true" ) {
                L.tileLayer(
                    "./static/offline-map/{z}/{x}/{y}.png",
                    {
                        attribution:'Kaartgegevens &copy; Kadaster',
                        maxZoom: 18,
                    }
                ).addTo(this.map);
            }
            else {
                L.tileLayer(
                    this.$store.getters["gamesettings/mapprovider"] + "/EPSG:3857/{z}/{x}/{y}.png",
                    {
                        attribution:'Kaartgegevens &copy; Kadaster',
                        maxZoom: 18,
                    }
                ).addTo(this.map);
            }

            // L.tileLayer(
            //     this.$store.getters["gamesettings/mapprovider"] + "/EPSG:3857/{z}/{x}/{y}.png",
            //     {
            //         attribution:'Kaartgegevens &copy; Kadaster',
            //         maxZoom: 18,
            //     }
            // ).addTo(this.map);

            // BVB 02 11 2021: add no entrance sign marker
            let no_entrance_icon = L.divIcon({
                className: 'no-entrance-icon',
                iconSize: [25,25],
            });
            let no_entrance_pos = L.latLng(52.082242552561105, 4.310340251144391);

            let no_entrance_marker = L.marker(no_entrance_pos, {icon:no_entrance_icon});   
            
            no_entrance_marker.addTo(this.map);

            // if (process.env.NODE_ENV === "development") {
            //     let latlng = new L.latLng(
            //         52.3699964, 
            //         4.9303107
            //     );

            //     this.setupLocation(latlng);
            // }
            
            // BVB 31-10-2022 this is to force the setup screen to draw 
            // the markers for selecting the endgame even when there is no geolocation
            // Moved from getLocation
            this.handleRouteNavigation();

            this.getLocation();
        },
        calculateMapBounds( fitBounds ) {
            // use all the markers to calculate the bounds and zoom of this map, once
            let locations = this.$store.getters['gamesettings/locations'];            
            
            let locationsGroup = L.featureGroup();
            //locationsGroup.addTo(this.map)

            locations.forEach( (location) => {

                if (location) {

                    let pos = location.latlng.split(",");
                    let qlatlng = this.convertLocationForTesting(
                        new L.LatLng(
                            Number(pos[0]),
                            Number(pos[1]) 
                        )
                    );                    

                    L.marker(qlatlng).addTo(locationsGroup); 
                }
            }); 

            let mapbounds = locationsGroup.getBounds().pad(.08);
            // only fix the bounds when we are testing at lange voorhout
            this.map.fitBounds( mapbounds,  {paddingBottomRight: [180,0]} );

            if (fitBounds) {
                // no zooming and panning for ya!
                this.map.setMaxBounds( this.map.getBounds()  );
                this.map.setMinZoom( this.map.getBoundsZoom( this.map.options.maxBounds ) );
                this.map.setMaxZoom( this.map.getBoundsZoom( this.map.options.maxBounds ) );
            }

        },
        startRound() {

            let caption = 'Begeleider <span style="color:black">&nbsp;-&nbsp;'  
                    + this.$store.getters['user/roomname']  + '&nbsp;-&nbsp;</span>'
                    //+ ' &nbsp;~ RONDE <span style="color:black">' +  (this.$store.getters['game/currentRound'] + 1) 
                    //+ '/' + this.$store.getters['gamesettings/numberOfRounds'] 
                    + '&nbsp;code ' + this.$store.getters['gamesettings/code'] ;


            this.$store.dispatch("setHeaderContent", {caption: caption} );

            // clear the old timer
            this.$store.dispatch('clock/clearTimer');

            // // start the new timer
            let roundtime = Math.max(.5,this.$store.getters['gamesettings/duration'] / this.$store.getters['gamesettings/numberOfRounds']);
            this.$store.dispatch('clock/startTimer', roundtime * 60);

            this.drawLocations();
        },
        setupLocation(latlng) {
            this.map.panTo(latlng);

            // let dist = L.latLng(latlng).distanceTo( this.center );
            // let atLangeVoorhout = (dist < 500);

            let atLangeVoorhout;

            if (process.env.VUE_APP_FIXED_LANGE_VOORHOUT === "true") {
                atLangeVoorhout = true;
            }
            else {
                let dist = L.latLng(latlng).distanceTo( this.center );
                atLangeVoorhout = (dist < 500);
            }
            
            if (!atLangeVoorhout) {

                //this.logger.info('niet op lange voorhout, gebruik locatie offset voor testen');
                console.log('niet op lange voorhout, gebruik locatie offset voor testen');

                // calculate lat/lng offset
                this.offsetlat = latlng.lat - this.center.lat;
                this.offsetlng = latlng.lng - this.center.lng;

                // BVB 31-10-2022 force redraw of endgame select buttons on this non standard location
                this.handleRouteNavigation();
            }
            this.calculateMapBounds( atLangeVoorhout );

            // add icon for the user
            this.user = new UserMarker(latlng);
            //this.user.setMaster();
            this.user.marker.addTo(this.map);

            this.watchLocation();

            // this.handleRouteNavigation();
        },
        convertLocationForTesting(latlng) {
            return new L.LatLng(
                latlng.lat + this.offsetlat,
                latlng.lng + this.offsetlng
            );
        },
        getLocation() {

            // we already aquired a location
            console.log( this.$root.location )
            if (this.$root.location) {

                let location = this.$root.location;
                 let latlng = new L.LatLng(
                    location.coords.latitude,
                    location.coords.longitude
                )

                this.setupLocation( latlng );
            }
            else {
                // no location yet, get it now
                console.log("get location")
                navigator.geolocation.getCurrentPosition((location) => {

                    console.log("got it")
                    if (this.$root.data) {
                        this.$root.data.location = location;
                    }

                    let latlng = new L.LatLng(
                        location.coords.latitude,
                        location.coords.longitude
                    )

                    this.setupLocation( latlng );
                });
            }
        },
        watchLocation() {
            // watchPosition
            this.watchTracker = navigator.geolocation.watchPosition( (location) => {

                let latlng = new L.LatLng(
                    location.coords.latitude,
                    location.coords.longitude
                )

                this.user.marker.setLatLng(latlng); 
            },
            (error) => {
                console.log(error);
            }, 
            this.gpsOptions);
        },
    },
    mounted() {
        if (this.$store.getters['user/roomname'] === '' ) {
            this.$router.push("/");
        }
        
        this.setupLeafletMap();
        this.$store.dispatch("setFooterVisibility", false);

        // listen to the location updates of the students
        this.$socket.client.on("message",(data)=>{
            // console.log("receive")
            // console.log(data)
            switch(data.student_action) {
                case "statusUpdate": {

                   // console.log(data.payload)

                    this.$store.dispatch('groups/updateGroup',data.payload);
                }
            }
        });

        this.$socket.client.on("clientleft",(socketid) => {
             this.$store.dispatch('groups/removeGroup',socketid);
        });

        let msg = {
            master_action: "requestGroupname",
        }
        let opts = SocketClientHelper.createMessageToStudents(msg)
        this.$socket.client.emit('event',opts); 

    },
    watch: {
        $route() {
            //console.log(from + ":" + to)
            // react to route changes...
            this.handleRouteNavigation();
        },
        countdownComplete: function (isComplete) {
            // //console.log("watch:" + oldvalue + ":" + isComplete)
            if (isComplete) {
                this.handleTimeout();
            }
        },
        // groupList: function () {
        //     console.log("grouplist change")
        // }
    },    
    computed: {
        countdownComplete() {
            return this.$store.getters["clock/countdownComplete"];
        },
        // selectedEndGameLocation() {
        //     return this.$store.getters["game/endgameLocation"];
        // },
        // groupList() {
        //     return this.$store.getters["socket/groupList"];
        // },
    },
};
</script>


<style scoped>
.page {
    position: relative;
    width: 100vw;
    height: 90vh;
    top:10vh;
    background-color: var(--green-middle-color);
}

.map-panel {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.button-panel {
    position: absolute;
    width:100%;

    z-index: 5;
}

.game-controls {
    position: absolute;
    z-index:2;
    width:100%;
    display: flex;
    justify-content: space-between;
    padding: calc(40px / var(--scale) ); 
    /* background-color: red; */
}

.button-leaderboard {
    width: calc(58px / var(--scale) ); 
    height: calc(58px / var(--scale) ); 
}

.game-code {
    width: calc(179px / var(--scale) );
    height: calc(67px / var(--scale) );
    background-color: white;
    font: calc(24px / var(--scale) )/calc(45px / var(--scale) ) Fira Sans Bold;
    text-align: center;
    border: 2px solid black;
    line-height:  calc(58px / var(--scale) );
    color: black;

    margin-top:10px;

}

.game-button {
    margin:10px;
}

.right {
    position: absolute;
    z-index:3;
    right:calc(68px / var(--scale) ); 
    width:20%;
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    padding-top: calc(160px / var(--scale) ); 
}

.prevbutton {
    margin-top:40px;
    margin-right:-5px;
}

.map {
    width: 100%;
    height: 100%;
}

.roundquitbutton {
    position: absolute;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%); 

    /* top: 0;
    left:0;
    right:0;
    bottom:0; */
    z-index: 2;
}



</style>